import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
import { Text } from './Text';
const Variants = {
    large: 'large',
    large700: 'large-700',
    medium: 'medium',
    medium700: 'medium-700',
    small: 'small',
};
const Body = ({ variant = Variants.medium, children, className = '', component, link = false, ...rest }) => {
    const styles = useStyles();
    const classNames = cx(className, {
        [styles.linkBodySmall]: link && variant === Variants.small,
        [styles.linkBodyMedium]: link && variant === Variants.medium,
        [styles.linkBodyLarge]: link && variant === Variants.large,
        [styles.bodySmall]: variant === Variants.small,
        [styles.bodyMedium]: variant === Variants.medium,
        [styles.bodyMediumBold]: variant === Variants.medium700,
        [styles.bodyLarge]: variant === Variants.large,
        [styles.bodyLargeBold]: variant === Variants.large700,
    });
    return (_jsx(Text, Object.assign({ component: component || 'p', className: classNames }, rest, { children: children }), void 0));
};
export default Body;
