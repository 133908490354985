import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/styles';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    counter: {
        fontSize: 60,
        fontWeight: 700,
    },
}));
export const CircleCountdown = ({ size = 216, onComplete = () => { }, autoStart = true, duration = 5, colors = ['#206AE9', '#FF333D'], }) => {
    const classes = useStyles();
    return (_jsx("div", Object.assign({ className: classes.root }, { children: _jsx(CountdownCircleTimer, Object.assign({ isPlaying: autoStart, duration: duration, colors: colors, colorsTime: [5, 0], isSmoothColorTransition: true, size: size, onComplete: onComplete }, { children: ({ remainingTime }) => (_jsx("div", Object.assign({ className: classes.counter }, { children: remainingTime }), void 0)) }), void 0) }), void 0));
};
