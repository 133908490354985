import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState, useCallback, useEffect, } from 'react';
import AutocompleteBase from '@material-ui/lab/Autocomplete';
import Input from './Input';
import useStyles from './styles/Autocomplete.styles';
import { Avatar } from '../Avatar';
import { Body } from '../Typography';
const Autocomplete = forwardRef(({ onSelectOption = () => { }, disabled = false, options = [{ value: 'Default value' }], locale, defaultValue, withIcon, value, clearOnBlur = true, allowNonExistedValue = false, isSearchable = false, ...props }, ref) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const filterOptions = useCallback((optionList, inputVal) => {
        const searchValue = inputVal.toLowerCase();
        return optionList.filter((option) => option.label?.toLowerCase()?.includes(searchValue));
    }, []);
    const renderOption = (option) => {
        if (withIcon) {
            const avatarProps = {};
            if (option?.src)
                avatarProps.src = option?.src;
            else
                avatarProps.placeHolder = option?.value;
            return (_jsxs("div", Object.assign({ className: classes.optionWithIcon }, { children: [_jsxs("div", Object.assign({ className: classes.optionWithIconFlexDiv }, { children: [_jsx(Avatar, Object.assign({ size: option.secondary ? 'm' : 's' }, avatarProps, { "aria-label": 'autocomplete-option-avatar' }), void 0), _jsxs("div", Object.assign({ className: classes.optionWithIconTextContainer }, { children: [_jsx("span", Object.assign({ "aria-label": 'autocomplete-option-label' }, { children: option.value }), void 0), option.secondary && (_jsx(Body, Object.assign({ className: classes.optionWithIconSecondaryText, component: 'span', variant: 'large', weight: 500, "aria-label": 'autocomplete-option-secondary-text' }, { children: option.secondary }), void 0))] }), void 0)] }), void 0), option.tag && option.tag] }), void 0));
        }
        return option.value;
    };
    const renderStartAdornment = () => {
        const option = options.find((o) => o.value === value?.value);
        if (withIcon && option) {
            const avatarProps = {};
            if (option?.src)
                avatarProps.src = option?.src;
            else
                avatarProps.placeHolder = option?.value;
            return (_jsx(Avatar, Object.assign({ "aria-label": 'autocomplete-option-avatar-image', className: classes.inputStartAdornment, size: 's' }, avatarProps), void 0));
        }
        return null;
    };
    const handleChange = useCallback((_, newValue) => {
        if (typeof newValue === 'string' && allowNonExistedValue) {
            if (newValue) {
                onSelectOption({
                    value: newValue,
                    label: newValue,
                });
            }
        }
        else if (newValue) {
            onSelectOption(newValue);
        }
        setIsOpen(false);
    }, [allowNonExistedValue, onSelectOption]);
    const handleInputChange = useCallback((_, newInputValue, reason) => {
        setInputValue(newInputValue);
        if (allowNonExistedValue && reason === 'input') {
            if (newInputValue) {
                onSelectOption({
                    value: newInputValue,
                    label: newInputValue,
                });
            }
            else {
                onSelectOption({ value: '' });
            }
        }
    }, [allowNonExistedValue, onSelectOption]);
    const handleBlur = useCallback(() => {
        setIsOpen(false);
        if (allowNonExistedValue && inputValue) {
            const trimmedValue = inputValue.trim();
            if (trimmedValue) {
                onSelectOption({
                    value: trimmedValue,
                    label: trimmedValue,
                });
            }
        }
    }, [allowNonExistedValue, inputValue, onSelectOption]);
    useEffect(() => {
        // Initialize inputValue when value prop changes
        if (value?.value) {
            setInputValue(value.value);
        }
    }, [value]);
    return (_jsx(AutocompleteBase, { ref: ref, defaultValue: defaultValue, value: value, inputValue: inputValue, options: options, filterOptions: (optionList) => (isSearchable ? filterOptions(optionList, inputValue) : optionList), disabled: disabled, freeSolo: allowNonExistedValue, noOptionsText: locale?.noOptionText || 'No Options', classes: { paper: classes.paper }, clearOnBlur: clearOnBlur, onBlur: handleBlur, onChange: handleChange, onInputChange: handleInputChange, getOptionLabel: (option) => {
            if (typeof option === 'string') {
                return option;
            }
            return option.value;
        }, getOptionSelected: (option, v) => {
            if (typeof v === 'string') {
                return option.value === v;
            }
            return option?.value === v?.value;
        }, renderOption: renderOption, onClose: () => setIsOpen(false), onOpen: () => setIsOpen(true), open: isOpen, renderInput: (params) => (_jsx(Input, Object.assign({ ref: params.InputProps.ref }, {
            fullWidth: params.fullWidth,
            size: params.size,
            id: params.id,
            inputProps: params.inputProps,
        }, props, { disabled: disabled, locale: locale, startAdornment: renderStartAdornment() }), void 0)) }, void 0));
});
export default Autocomplete;
