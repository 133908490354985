import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: [
            'Manrope',
            '"Segoe UI"',
            'Tahoma',
            'Geneva',
            'Verdana',
            'sans-serif',
        ].join(','),
        borderRadius: '8px',
        lineHeight: '24px',
        textTransform: 'none',
        boxShadow: 'none',
        transition: 'ease-out 300ms',
        fontWeight: 700,
        minWidth: '36px',
    },
    fixedLabel: {
        width: '240px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'center',
    },
    fixedLabel_small: {
        height: '36px',
        fontSize: '14px',
        padding: '6px 16px',
    },
    fixedLabel_medium: {
        height: '40px',
        fontSize: '15px',
        padding: '8px 16px',
    },
    fixedLabel_large: {
        height: '48px',
        fontSize: '16px',
        padding: '12px 16px',
    },
    fixedIcon: {
        overflow: 'hidden',
        textAlign: 'center',
        fontSize: '16px',
    },
    fixedIcon_small: {
        height: '36px',
        width: '36px',
        padding: '10px',
    },
    fixedIcon_medium: {
        height: '40px',
        width: '40px',
        padding: '12px',
    },
    fixedIcon_large: {
        height: '48px',
        width: '48px',
        padding: '16px',
    },
    autoWidth: {
        width: 'auto',
    },
    autoWidth_small: {
        height: '36px',
        fontSize: '14px',
        padding: '6px 16px 6px 20px',
    },
    autoWidth_medium: {
        height: '40px',
        fontSize: '15px',
        padding: '8px 16px 8px 20px',
    },
    autoWidth_large: {
        height: '48px',
        fontSize: '16px',
        padding: '12px 16px 12px 20px',
    },
    primary: {
        background: theme.palette.primary.main,
        color: '#ffffff',
        '&:hover': {
            background: theme.palette.primary.light,
        },
        '&:disabled': {
            background: theme.palette.primary.main,
            opacity: '0.45',
            color: '#ffffff',
        },
        '&:active': {
            background: theme.palette.primary.dark,
        },
    },
    secondary: {
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.primary.light,
        },
        '&:disabled': {
            color: '#b4b8c0',
        },
        '&:active': {
            background: theme.palette.secondary.light,
            color: theme.palette.primary.dark,
        },
    },
    ghost: {
        background: 'transparent',
        color: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.primary.light,
        },
        '&:disabled': {
            color: '#b4b8c0',
        },
        '&:active': {
            background: theme.palette.secondary.light,
            color: theme.palette.primary.dark,
        },
    },
    marginLeft: {
        marginLeft: '12px',
    },
    flash: {
        border: '4px solid transparent',
        boxSizing: 'border-box',
        backgroundImage: 'conic-gradient(from 180deg, #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
        backgroundOrigin: 'border-box',
        boxShadow: `1px 1000px 1px ${theme.palette.common.white} inset`,
        '&:hover': {
            boxShadow: 'none',
        },
    },
}));
export default useStyles;
