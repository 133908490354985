import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Checkbox as MUICheckbox, } from '@material-ui/core';
import cx from 'classnames';
import useStyles from './styles/Checkbox.styles';
import { Check, Minus } from '../icons';
import Caption from '../Typography/Caption';
export const Checkbox = forwardRef(({ ...rest }, ref) => {
    const styles = useStyles();
    const { label, className, id, labelClassName, parentContainerClassname, ...restProps } = rest;
    const selectedId = id || `fallback-checkbox-id-${label || '1'}`;
    return (_jsxs("div", Object.assign({ className: parentContainerClassname }, { children: [_jsx(MUICheckbox, Object.assign({ id: selectedId, ref: ref, disableRipple: true, color: 'primary', icon: _jsx("span", { className: styles.icon }, void 0), checkedIcon: (_jsx("span", Object.assign({ className: cx(styles.icon, styles.checkedIcon) }, { children: _jsx(Check, { style: { fontSize: 16 } }, void 0) }), void 0)), indeterminateIcon: (_jsx("span", Object.assign({ className: cx(styles.icon, styles.indeterminateIcon) }, { children: _jsx(Minus, { style: { fontSize: 16 } }, void 0) }), void 0)), classes: {
                    root: cx(styles.rootStyles, className),
                    checked: styles.checkedStyles,
                    indeterminate: styles.indeterminateStyles,
                } }, restProps), void 0), label && _jsx("label", Object.assign({ htmlFor: selectedId, className: cx(styles.checkboxLabel, labelClassName) }, { children: _jsx(Caption, Object.assign({ variant: 'bold' }, { children: label }), void 0) }), void 0)] }), void 0));
});
