import { makeStyles } from '@material-ui/core';
const white = '#ffffff';
const useStyles = makeStyles((theme) => ({
    rootStyles: {
        outline: 'none',
        margin: 0,
        color: theme.palette.grey[400],
        borderRadius: 4,
        padding: 2,
        background: 'transparent',
        border: '1px solid transparent',
        '&:hover': {
            background: 'transparent',
        },
        '&:hover $icon': {
            borderColor: theme.palette.primary.light,
            background: white,
        },
        // '&:focus-within': {
        //   borderColor: theme.palette.primary.main,
        //   borderRadius: '6px',
        // },
        '&.Mui-disabled $icon': {
            padding: 0,
            bordeColor: theme.palette.grey[300],
            background: theme.palette.grey[100],
        },
    },
    checkedStyles: {
        '&:hover $icon': {
            borderColor: theme.palette.primary.light,
            background: theme.palette.primary.light,
        },
        '&.Mui-disabled $icon': {
            padding: 0,
            background: theme.palette.grey[300],
            borderColor: theme.palette.grey[300],
            color: theme.palette.grey[500],
        },
    },
    indeterminateStyles: {
        '&:hover $icon': {
            borderColor: theme.palette.primary.light,
            background: theme.palette.primary.light,
            padding: 0,
        },
        '&.Mui-disabled $icon': {
            padding: 0,
            background: theme.palette.grey[300],
            borderColor: theme.palette.grey[300],
            color: theme.palette.grey[500],
        },
    },
    icon: {
        width: 20,
        height: 20,
        boxSizing: 'border-box',
        border: `2px solid ${theme.palette.grey[400]}`,
        background: white,
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: white,
    },
    checkedIcon: {
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
    },
    indeterminateIcon: {
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
    },
    checkboxLabel: {
        marginLeft: '14px',
        display: 'inline-block',
        userSelect: 'none',
    },
}));
export default useStyles;
