import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Avatar } from '../../Avatar';
import { Body, Caption } from '../../Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    nameContainer: {
        verticalAlign: 'super',
        marginLeft: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    customId: {
        color: theme.palette.grey[700],
        maxWidth: 192,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));
export const AvatarNameCell = ({ name = 'Name Surname', src, customId, placeHolder = 'Name Surname', }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [_jsx(Avatar, { src: src, placeHolder: placeHolder, size: 's' }, void 0), _jsxs("div", Object.assign({ className: classes.nameContainer }, { children: [_jsx(Body, Object.assign({ component: 'span', variant: 'medium-700' }, { children: name }), void 0), customId && _jsx(Caption, Object.assign({ className: classes.customId }, { children: customId }), void 0)] }), void 0)] }), void 0));
};
