import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 12,
        minWidth: 180,
        boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
    },
    listItem: {
        paddingTop: 8,
        paddingBottom: 8,
        color: theme.palette.common.black,
        fontSize: 15,
        fontWeight: 500,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
        },
    },
}));
export default useStyles;
