import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons, Button } from '../..';
import { MultipleAvatar } from '../Table';
import { Body, Caption } from '../Typography';
const useStyles = makeStyles((theme) => ({
    container: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    assignedTeamsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 4,
    },
    actions: {
        display: 'flex',
        gap: 4,
    },
    assignedLabel: {
        color: theme.palette.grey[500],
    },
    note: {
        marginTop: 4,
        color: theme.palette.grey[700],
        wordBreak: 'break-word',
    },
    title: {
        wordBreak: 'break-word',
    },
    deleteButton: {
        color: theme.palette.grey[500],
        marginTop: 4,
        '& svg': {
            width: 20,
            height: 20,
        },
    },
}));
const Note = ({ style, className, title, locale, note, onClickEdit, onClickDelete, avatarPropList, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ style: style, className: cx(classes.container, className) }, rest, { children: [_jsxs("div", Object.assign({ className: classes.topContainer }, { children: [_jsxs("div", { children: [_jsx(Body, Object.assign({ variant: 'large-700', className: classes.title }, { children: title }), void 0), _jsxs("div", Object.assign({ className: classes.assignedTeamsContainer }, { children: [_jsxs(Caption, Object.assign({ className: classes.assignedLabel }, { children: [locale.assigned, ":"] }), void 0), _jsx(MultipleAvatar, { avatarPropList: avatarPropList }, void 0)] }), void 0)] }, void 0), _jsxs("div", Object.assign({ className: classes.actions }, { children: [onClickEdit && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.EditFilled, {}, void 0), onClick: onClickEdit, size: 'small', variant: 'fixedIcon' }, void 0)), onClickDelete && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.Trash, { className: classes.deleteButton }, void 0), onClick: onClickDelete, size: 'small', variant: 'fixedIcon' }, void 0))] }), void 0)] }), void 0), _jsx(Body, Object.assign({ variant: 'medium', className: classes.note }, { children: note }), void 0)] }), void 0));
};
export default Note;
