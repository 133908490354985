import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useRef, useState, } from 'react';
import { InputBase } from '@material-ui/core';
import { getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import DatePickerComponent from 'react-datepicker';
import cx from 'classnames';
import { dateTypeEnum, getMonthNames, JobDoneLocales, } from '../../utils';
import { CalendarFilled } from '../icons';
import { Dropdown } from '../Input';
import { FormControl } from '../..';
import { useStyles as useTimePickerStyles } from './styles/TimePicker.styles';
import { useStyles, useInputEndAndormentStyles } from './styles/Datepicker.styles';
import useInputStyles from '../Input/styles/Input.styles';
export const DatePicker = ({ className, inputClassName, value, inputLocale = {
    errorMessage: '',
    label: 'DatePicker',
    placeholder: 'Choose a date...',
}, locale = JobDoneLocales.ENGLISH, hasError = false, dateFormat = 'dd.MM.yyyy', onChange = console.log, hiddenLabel, required, id, yearOptionStart = 1900, onFocus, onBlur, ...props }) => {
    const timePickerClasses = useTimePickerStyles();
    const classes = useStyles({ step: dateTypeEnum.DATE });
    const inputClasses = useInputStyles();
    const [isActive, setIsActive] = useState(false);
    const ref = useRef(null);
    const years = useMemo(() => range(yearOptionStart, getYear(new Date()) + 5, 1).reverse().map((year) => ({
        value: year,
        label: year,
    })), [yearOptionStart]);
    const months = useMemo(() => getMonthNames(locale).map((month) => ({
        label: month,
        value: month,
    })), [locale]);
    const handleFocus = () => {
        onFocus && onFocus();
        setIsActive(true);
    };
    const handeBlur = () => {
        onBlur && onBlur();
        setIsActive(false);
    };
    return (_jsx("div", Object.assign({ className: cx(timePickerClasses.container, className) }, { children: _jsx(FormControl, Object.assign({ active: isActive, hiddenLabel: hiddenLabel, error: hasError, errorMessage: inputLocale?.errorMessage, id: id, required: required, label: inputLocale?.label || '' }, { children: _jsx(DatePickerComponent, Object.assign({}, props, { renderCustomHeader: ({ date: componentDate, changeYear, changeMonth, }) => (_jsxs("div", Object.assign({ className: classes.calendarHeader }, { children: [_jsx(Dropdown, { options: years, hiddenLabel: true, value: { value: getYear(componentDate), label: getYear(componentDate) }, onChange: (selectedOption) => changeYear(parseInt(selectedOption.value, 10)) }, void 0), _jsx(Dropdown, { options: months, hiddenLabel: true, value: months[getMonth(componentDate)], onChange: (selectedOption) => changeMonth(months.indexOf(selectedOption)) }, void 0)] }), void 0)), selected: value, onChange: onChange, onFocus: handleFocus, onBlur: handeBlur, onCalendarClose: () => setIsActive(false), customInput: (_jsx(InputBase, { inputRef: ref, "aria-label": 'time-picker-input', className: cx(inputClasses.input, className, { [inputClasses.inputError]: hasError }), placeholder: inputLocale.placeholder, style: { fontWeight: 500 }, id: id, onKeyUp: (e) => {
                        if (e.key === 'Enter') {
                            ref?.current?.blur();
                        }
                    }, endAdornment: (_jsx(CalendarFilled, { className: cx(useInputEndAndormentStyles().calendarEndAdornment, {
                            [useInputEndAndormentStyles().calendarEndAdornmentActive]: isActive,
                        }) }, void 0)) }, void 0)), showPopperArrow: false, dateFormat: dateFormat, calendarClassName: classes.calendarRoot, calendarStartDay: 1 }), void 0) }), void 0) }), void 0));
};
