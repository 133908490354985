import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Body, Subtitle } from '../Typography';
import useStyles from './styles/FilesDragAndDrop.styles';
import { Icons } from '../..';
export const FilesDragAndDrop = ({ onUpload, count, formats, openDialogOnClick = false, onDragEnter, onDragLeave, onDrop, }) => {
    const classes = useStyles();
    const [dragging, setDragging] = useState(false);
    const drag = useRef(null);
    const drop = useRef(null);
    const input = useRef(null);
    useEffect(() => {
        drop.current?.addEventListener('dragover', handleDragOver);
        drop.current?.addEventListener('drop', handleDrop);
        drop.current?.addEventListener('dragenter', handleDragEnter);
        drop.current?.addEventListener('dragleave', handleDragLeave);
        return () => {
            drop.current?.removeEventListener('dragover', handleDragOver);
            drop.current?.removeEventListener('drop', handleDrop);
            drop.current?.removeEventListener('dragenter', handleDragEnter);
            drop.current?.removeEventListener('dragleave', handleDragLeave);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const files = e.dataTransfer ? [...e.dataTransfer.files] : [];
        onDrop && onDrop(files);
        handleUpload(files);
    };
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target !== drag.current) {
            setDragging(true);
            onDragEnter && onDragEnter();
        }
    };
    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target === drag.current) {
            setDragging(false);
            onDragLeave && onDragLeave();
        }
    };
    const handleSelectFiles = (e) => {
        if (e.target.files) {
            const files = [...e.target.files];
            handleUpload(files);
        }
    };
    const handleUpload = (files) => {
        if (count && count < files.length) {
            console.log('error');
            return;
        }
        if (formats
            && files.some((file) => !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
            console.log('error');
            return;
        }
        if (files && files.length) {
            onUpload(files);
        }
    };
    const openFileDialog = () => {
        input && input.current?.click();
    };
    return (_jsxs("div", Object.assign({ ref: drop, style: {
            cursor: openDialogOnClick ? 'pointer' : 'default',
        }, onClick: openDialogOnClick ? openFileDialog : undefined, role: 'button', "aria-hidden": true }, { children: [openDialogOnClick && (_jsx("input", { ref: input, type: 'file', accept: formats ? formats.map((format) => `.${format}`).join(', ') : undefined, multiple: !count || count > 1, onChange: handleSelectFiles, className: classes.input }, void 0)), _jsxs("div", Object.assign({ className: cx(classes.container, {
                    [classes.dragging]: dragging,
                }) }, { children: [_jsx(Icons.File, { className: classes.image }, void 0), _jsx(Subtitle, Object.assign({ className: classes.title }, { children: "Drag & Drop" }), void 0), _jsxs(Body, Object.assign({ className: classes.description }, { children: ["or", ' ', _jsx("span", { children: "browse" }, void 0), ' ', "to choose a file"] }), void 0)] }), void 0)] }), void 0));
};
