import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import cx from 'classnames';
import { theme } from '../../../utils';
import { DropdownMenu } from '../../DropdownMenu';
import { Icons } from '../../..';
import { Button } from '../../Button';
import { Body } from '../../Typography';
import { MenuItemsTypeEnum } from '../../../interfaces';
const useStyles = makeStyles(() => ({
    button: {
        color: theme.palette.grey[500],
    },
    menuItem: {
        display: 'flex',
        gap: 10,
    },
    regular: {
        color: theme.palette.grey[500],
        '&:hover *': {
            color: theme.palette.primary.main,
        },
    },
    error: {
        '& > *': {
            color: `${theme.palette.error.main} !important`,
        },
        '&:hover': {
            backgroundColor: theme.palette.error.light,
        },
    },
}));
export const ThreeDotsButton = ({ menuItems = [], classname }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { className: cx(classes.button, classname), variant: 'fixedIcon', color: 'ghost', icon: _jsx(Icons.MoreHorizontal, {}, void 0), onClick: handleClick }, void 0), menuItems.length > 0 && (_jsx(DropdownMenu, Object.assign({ "aria-label": 'menu-items', anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems.map(({ key, label, icon, type, ...rest }) => (_jsxs(DropdownMenu.Item, Object.assign({ className: cx(classes.menuItem, {
                        [classes.regular]: type === MenuItemsTypeEnum.REGULAR,
                        [classes.error]: type === MenuItemsTypeEnum.ERROR,
                    }) }, rest, { children: [icon || _jsx(_Fragment, {}, void 0), _jsx(Body, Object.assign({ variant: 'large' }, { children: label }), void 0)] }), key))) }), void 0))] }, void 0));
};
