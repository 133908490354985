import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { useDropdownMenu } from '../../utils';
import { Button } from '../Button';
import { DropdownMenu } from '../DropdownMenu';
import { MoreHorizontal } from '../icons';
import { Body } from '../Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: 16,
        backgroundColor: theme.palette.grey[100],
        padding: 16,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
    },
    headerDetails: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    timeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
    },
    time: {
        color: theme.palette.grey[700],
    },
    isRead: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.error.main,
    },
    content: {
        marginTop: 14,
        marginBottom: 10,
    },
    moreIcon: {
        color: theme.palette.grey[500],
    },
    horizontalLine: {
        width: '100%',
        height: 1,
        background: theme.palette.grey[300],
        margin: '8px 0px',
    },
    dropdownDeleteItem: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.main,
        },
        '&:active': {
            backgroundColor: '#FFEAE8',
            color: theme.palette.error.main,
        },
    },
    disabledMenuItem: {
        color: theme.palette.grey[500],
        cursor: 'not-allowed',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[500],
        },
    },
}));
export const Informational = ({ id, locale, icon, isRead, isLoading, handleMarkAsRead, handleDeleteNotification, }) => {
    const classes = useStyles();
    const { anchorEl, handleClick, handleClose } = useDropdownMenu();
    return (_jsxs("div", Object.assign({ className: classes.root, id: id }, { children: [_jsxs("div", Object.assign({ className: classes.header }, { children: [_jsxs("div", Object.assign({ className: classes.title }, { children: [icon, _jsx(Body, Object.assign({ variant: 'medium-700' }, { children: locale.title }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.headerDetails }, { children: [_jsxs("div", Object.assign({ className: classes.timeContainer }, { children: [_jsx(Body, Object.assign({ className: classes.time }, { children: locale.time }), void 0), !isRead && _jsx("div", { className: classes.isRead }, void 0)] }), void 0), _jsxs(_Fragment, { children: [_jsx(Button, { color: 'ghost', variant: 'fixedIcon', icon: _jsx(MoreHorizontal, { className: classes.moreIcon }, void 0), onClick: handleClick, disabled: isLoading }, void 0), _jsxs(DropdownMenu, Object.assign({ anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: [_jsx(DropdownMenu.Item, Object.assign({ onClick: () => !isRead && handleMarkAsRead?.(id), className: cx({ [classes.disabledMenuItem]: isRead }) }, { children: locale.markAsRead }), void 0), _jsx("div", { className: classes.horizontalLine }, void 0), _jsx(DropdownMenu.Item, Object.assign({ className: classes.dropdownDeleteItem, onClick: () => handleDeleteNotification?.(id) }, { children: locale.deleteNotification }), void 0)] }), void 0)] }, void 0)] }), void 0)] }), void 0), locale.body && (_jsx("div", Object.assign({ className: classes.content }, { children: _jsx("span", { dangerouslySetInnerHTML: { __html: locale.body } }, void 0) }), void 0))] }), void 0));
};
