import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, forwardRef, useRef, } from 'react';
import { Checkbox } from '../Checkbox';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCombinedRefs = (...refs) => {
    const targetRef = useRef();
    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref)
                return;
            if (typeof ref === 'function') {
                ref(targetRef.current);
            }
            else {
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [refs]);
    return targetRef;
};
const IndeterminateCheckbox = forwardRef(
// eslint-disable-next-line react/prop-types
({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);
    return (_jsx(Checkbox
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    , Object.assign({ 
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        onClick: (e) => e?.stopPropagation(), indeterminate: indeterminate, ref: combinedRef }, rest), void 0));
});
export default IndeterminateCheckbox;
