import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar as MUIAvatar, Badge, } from '@material-ui/core';
import cx from 'classnames';
import { Icons } from '../..';
import { Tooltip } from '../Tooltip';
import useStyles from './styles/Avatar.styles';
import { getPlaceHolderText, SIZES, TYPES, } from './utils';
export function Avatar(props) {
    const { size = SIZES.m, type = TYPES.default, badge, className, containerClassName, children, placeHolder, showToolTip = false, borderColor, ...rest } = props;
    const styles = useStyles({ borderColor });
    const classes = cx(styles.root, styles[size], className, styles.borderStyle);
    const renderContent = () => {
        const defaultContent = (_jsx("div", Object.assign({ className: cx(styles[type], containerClassName) }, { children: (showToolTip && placeHolder) ? (_jsx(Tooltip, Object.assign({ title: placeHolder }, { children: _jsx(MUIAvatar, Object.assign({ className: classes }, rest, { children: placeHolder ? getPlaceHolderText(placeHolder) : children }), void 0) }), void 0)) : (_jsx(MUIAvatar, Object.assign({ className: classes }, rest, { children: placeHolder ? getPlaceHolderText(placeHolder) : children }), void 0)) }), void 0));
        const getBadgeContent = () => {
            if (badge === 'approved')
                return _jsx(Icons.ApprovedCircleCheck, {}, void 0);
            if (badge === 'awaitingApproval')
                return _jsx(Icons.AwaitingApproval, {}, void 0);
            if (badge === 'folder')
                return _jsx(Icons.FolderAvatarVersion, { className: styles.folderIcon }, void 0);
            return undefined;
        };
        if (badge) {
            return (_jsx(Badge, Object.assign({ overlap: 'circular', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, variant: (badge === 'approved' || badge === 'awaitingApproval' || badge === 'folder') ? 'standard' : 'dot', classes: {
                    badge: cx(styles.badge, styles[`badge_${badge}`]),
                }, badgeContent: getBadgeContent() }, { children: defaultContent }), void 0));
        }
        return defaultContent;
    };
    return renderContent();
}
