import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './BreakEntry.styles';
import { Caption } from '../../../Typography';
import { Break } from '../../../icons';
export const BreakEntry = ({ testId, title = 'Break', time = '7:00 - 11:00', type, duration, className, selected, disabled = false, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, {
            [classes.selected]: selected,
            [classes.disabled]: disabled,
            [`${String(className)}`]: !!className,
        }) }, { children: [_jsxs("div", Object.assign({ className: classes.topContainer }, { children: [_jsxs("div", Object.assign({ className: classes.iconAndDurationContainer }, { children: [_jsx(Break, { "aria-label": 'break-icon', className: classes.breakIcon }, void 0), _jsx(Caption, Object.assign({ className: classes.durationBlock }, { children: duration }), void 0)] }), void 0), _jsx(Caption, Object.assign({ variant: 'regular', className: classes.timeRange }, { children: time }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.bottomContainer }, { children: [_jsx(Caption, Object.assign({ variant: 'bold', className: classes.titleBlock }, { children: title }), void 0), _jsx(Caption, Object.assign({ variant: 'bold', className: classes.typeBlock }, { children: type }), void 0)] }), void 0)] }), void 0));
};
