import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { usePagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Icons } from '../../index';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        gap: 4,
        alignItems: 'center',
    },
    item: {
        userSelect: 'none',
        display: 'flex',
        transition: theme.transitions.create('all'),
        color: theme.palette.common.black,
        '& > button': {
            fontFamily: theme.typography.fontFamily,
            fontSize: 15,
            color: theme.palette.common.black,
            fontWeight: 500,
            transition: theme.transitions.create('all'),
            outline: 'none',
            backgroundColor: 'unset',
            minWidth: 40,
            height: 40,
            borderRadius: 8,
            cursor: 'pointer',
            '&:hover:enabled': {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.primary.light,
            },
            '&:disabled': {
                color: `${theme.palette.grey[500]} !important`,
                cursor: 'not-allowed',
            },
            border: 'none',
        },
    },
    selected: {
        fontWeight: 500,
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${theme.palette.primary.main} !important`,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: `${theme.palette.common.white} !important`,
        },
    },
    navBtn: {
        display: 'flex',
        alignItems: 'center',
        color: `${theme.palette.primary.main} !important`,
        verticalAlign: 'middle',
        '& > svg': {
            verticalAlign: 'middle',
        },
    },
    navBtnDisabled: {
        display: 'none',
    },
    navBtnText: {
        fontWeight: 700,
    },
    navBtnPrev: {
        marginRight: 40,
    },
    navBtnNext: {
        marginLeft: 40,
    },
}));
export const Pagination = ({ locale = { next: 'Next', prev: 'Back' }, totalCount = 10, value = 1, setValue = () => { }, className, isMini, disabled, ...rest }) => {
    const classes = useStyles();
    const { items } = usePagination({
        count: totalCount,
        onChange: (_, p) => setValue(p),
        page: value,
    });
    if (isMini) {
        return (_jsx("ul", Object.assign({ className: cx(classes.root, className) }, rest, { children: items.map(({ page, type, selected, ...item }, index) => {
                let children = null;
                if (type === 'page' && selected) {
                    children = (_jsx("button", Object.assign({ type: 'button', className: classes.selected }, item, { disabled: item.disabled || disabled }, { children: page }), void 0));
                }
                else if (type === 'previous') {
                    children = (_jsx("button", Object.assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnPrev) }, { children: _jsx(Icons.ShevronLeft, {}, void 0) }), void 0));
                }
                else if (type === 'next') {
                    children = (_jsx("button", Object.assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnNext) }, { children: _jsx(Icons.ShevronRight, {}, void 0) }), void 0));
                }
                // eslint-disable-next-line react/no-array-index-key
                return _jsx("li", Object.assign({ className: classes.item }, { children: children }), index);
            }) }), void 0));
    }
    return (_jsx("ul", Object.assign({ className: cx(classes.root, className) }, rest, { children: items.map(({ page, type, selected, ...item }, index) => {
            let children = null;
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = '…';
            }
            else if (type === 'page') {
                children = (_jsx("button", Object.assign({ type: 'button', className: cx({ [classes.selected]: selected }) }, item, { disabled: item.disabled || disabled }, { children: page }), void 0));
            }
            else if (type === 'previous') {
                children = (_jsxs("button", Object.assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnPrev) }, { children: [_jsx(Icons.ShevronLeft, {}, void 0), _jsx("span", Object.assign({ className: classes.navBtnText }, { children: locale.prev }), void 0)] }), void 0));
            }
            else if (type === 'next') {
                children = (_jsxs("button", Object.assign({ type: 'button' }, item, { disabled: item.disabled || disabled, className: cx(classes.navBtn, classes.navBtnNext) }, { children: [_jsx("span", Object.assign({ className: classes.navBtnText }, { children: locale.next }), void 0), _jsx(Icons.ShevronRight, {}, void 0)] }), void 0));
            }
            // eslint-disable-next-line react/no-array-index-key
            return _jsx("li", Object.assign({ className: classes.item }, { children: children }), index);
        }) }), void 0));
};
