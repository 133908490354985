import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useState, } from 'react';
import { makeStyles } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { Dialog } from '../Dialog';
import { getCroppedImg, urltoFile } from './canvasUtils';
import { theme } from '../../utils';
import { Tooltip } from '../Tooltip';
const useStyles = makeStyles(() => ({
    uploadImage: {
        display: 'block',
        cursor: 'pointer',
        '& > input[type="file"]': {
            display: 'none',
        },
    },
    cropperContainer: {
        position: 'relative !important',
        height: 500,
        '& > img': {
            position: 'relative !important',
        },
    },
    overlay: {
        position: 'relative',
        '& > :first-child': {
            transition: theme.transitions.create('all'),
            borderRadius: ({ cropShape }) => (cropShape === 'round' ? '50%' : '100%'),
            cursor: 'pointer',
            border: '1px solid transparent',
            '&:hover': {
                border: ({ borderColorOnHover }) => `1px solid ${borderColorOnHover}`,
            },
        },
        '&:hover $removeFile': {
            opacity: 1,
        },
    },
    tooltip: {
        display: 'inline-block',
    },
    removeFile: {
        position: 'absolute',
        transition: theme.transitions.create('all'),
        borderRadius: ({ cropShape }) => (cropShape === 'round' ? '50%' : '100%'),
        right: 0,
        zIndex: 1,
        opacity: 0,
        border: '1px solid transparent',
        '&:hover': {
            border: ({ borderColorOnHover }) => `1px solid ${borderColorOnHover}`,
        },
        cursor: 'pointer',
    },
}));
export const ImageUpload = ({ id, locale = {
    confirmButton: 'Confirm',
    declineButton: 'Cancel',
    title: 'Crop image',
}, tooltip = 'Upload image', crop = { x: 0, y: 0 }, zoom = 1, aspect = 1 / 1, cropShape = 'round', component, borderColorOnHover = theme.palette.grey[500], disableHover = false, getImageAsFile, getCroppedImage, hasDeleteBtn = false, fileName, isDisabled, hasTooltip = true, }) => {
    const classes = useStyles({
        borderColorOnHover: disableHover ? 'transparent' : borderColorOnHover,
        cropShape,
    });
    const [imageSrc, setImageSrc] = useState(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cropState, setCropState] = useState(crop);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [zoomState, setZoomState] = useState(zoom);
    const [croppedImage, setCroppedImage] = useState(undefined);
    const [inputKey, setInputKey] = useState(Math.random());
    const [imageAsFile, setImageAsFile] = useState(null);
    const onCropComplete = useCallback((_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);
    const readFile = (file) => new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
    const showCroppedImage = useCallback(async () => {
        try {
            if (imageSrc && croppedAreaPixels) {
                const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
                setCroppedImage(croppedImage);
                if (getCroppedImage) {
                    getCroppedImage(croppedImage);
                }
                const file = await urltoFile(croppedImage, fileName || 'croppedImage', 'image/jpeg');
                if (file) {
                    getImageAsFile(file);
                }
                setImageAsFile(file);
                setIsModalOpen(false);
                setImageSrc(undefined);
                setInputKey(Math.random());
            }
        }
        catch (e) {
            console.error(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageSrc, croppedAreaPixels]);
    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
            setInputKey(Math.random());
            setIsModalOpen(true);
            setImageAsFile(null);
        }
    };
    const onClose = useCallback(() => {
        setImageSrc(undefined);
        setCroppedImage(undefined);
        setImageAsFile(null);
        setIsModalOpen(false);
        setInputKey(Math.random());
    }, []);
    const handleDeleteFile = (e) => {
        e.preventDefault();
        onClose();
        getImageAsFile(null);
        getCroppedImage && getCroppedImage(undefined);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, Object.assign({ locale: locale, open: isModalOpen, handleClose: onClose, handleConfirm: showCroppedImage }, { children: _jsx("div", { children: _jsx(Cropper, { image: imageSrc, crop: cropState, zoom: zoomState, aspect: aspect, cropShape: cropShape, onCropChange: setCropState, onCropComplete: onCropComplete, classes: {
                            containerClassName: classes.cropperContainer,
                        }, onZoomChange: setZoomState }, void 0) }, void 0) }), void 0), _jsxs("label", Object.assign({ className: classes.uploadImage, htmlFor: id || 'image-upload' }, { children: [_jsx("input", { type: 'file', onClick: () => {
                            setCroppedImage(undefined);
                            setImageAsFile(null);
                        }, onChange: onFileChange, accept: 'image/*', disabled: isDisabled, id: id || 'image-upload' }, inputKey), hasTooltip ? (_jsx(Tooltip, Object.assign({ title: tooltip, className: classes.tooltip }, { children: _jsxs("div", Object.assign({ className: classes.overlay }, { children: [component({ croppedImage, originalImage: imageSrc, imageAsFile }), hasDeleteBtn && (_jsx("button", Object.assign({ disabled: isDisabled, onClick: handleDeleteFile, className: classes.removeFile }, { children: "x" }), void 0))] }), void 0) }), void 0))
                        : component({
                            croppedImage, originalImage: imageSrc, imageAsFile, handleDeleteFile,
                        })] }), void 0)] }, void 0));
};
