import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppBar, Toolbar, IconButton, } from '@material-ui/core';
import cx from 'classnames';
import { SmallLogo } from '../icons';
import useStyles from './styles/NavigationBar.styles';
export const NavigationBar = ({ children, className, onIconClick, ...rest }) => {
    const styles = useStyles();
    return (_jsx(AppBar, Object.assign({}, rest, { classes: {
            root: cx(styles.root, className),
        } }, { children: _jsxs(Toolbar, Object.assign({ classes: {
                root: styles.toolbar,
            } }, { children: [_jsx(IconButton, Object.assign({ onClick: onIconClick, disableRipple: true, className: styles.logoBlock }, { children: _jsx(SmallLogo, {}, void 0) }), void 0), children] }), void 0) }), void 0));
};
