import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Icons } from '../..';
const useStyles = makeStyles(() => ({
    root: ({ withDropdownIcon }) => ({
        userSelect: 'none',
        padding: '4px 8px',
        borderRadius: 6,
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: withDropdownIcon ? 'pointer' : 'default',
    }),
    dropdownIcon: {
        '& > svg': {
            width: 14,
            height: 14,
            verticalAlign: 'middle',
        },
    },
}));
export const Tag = ({ children = 'Business', color = '#028CA2', backgroundColor = '#E0FCFF', className, withDropdownIcon = false, ...props }) => {
    const classes = useStyles({ withDropdownIcon });
    return (_jsxs("span", Object.assign({}, props, { className: cx(classes.root, className), style: { ...props.style, color, backgroundColor } }, { children: [children, withDropdownIcon && _jsx(Icons.DropdownIcon, { className: classes.dropdownIcon }, void 0)] }), void 0));
};
