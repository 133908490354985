import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import cx from 'classnames';
import { sortingOrderEnum } from '@jobdone/jobdone-shared-library';
import { Body } from '../../Typography';
import { Icons } from '../../..';
const useStyles = makeStyles((theme) => ({
    sortIcon: {
        verticalAlign: 'bottom',
        marginLeft: 6,
        color: theme.palette.grey[500],
        cursor: 'pointer',
        '& > svg': {
            width: 17,
        },
    },
    sortingBtn: {
        border: 'none',
        backgroundColor: 'unset',
        cursor: 'pointer',
        '&:hover > *': {
            color: theme.palette.primary.main,
        },
        '&:hover $noSort': {
            opacity: 1,
        },
    },
    activeColumn: {
        '& $sortIcon': {
            color: theme.palette.primary.dark,
        },
    },
    noSort: {
        transition: theme.transitions.create('all'),
        opacity: 0,
    },
}));
export const SortableColumn = ({ title = 'Column 1', getSortType, testId = 'table-column-sort-btn', }) => {
    const classes = useStyles();
    const [sortType, setSortType] = useState(sortingOrderEnum.ASC);
    const handleClick = () => {
        switch (sortType) {
            case sortingOrderEnum.DESC:
                setSortType('');
                getSortType('');
                break;
            case sortingOrderEnum.ASC:
                setSortType(sortingOrderEnum.DESC);
                getSortType(sortingOrderEnum.DESC);
                break;
            default:
                setSortType(sortingOrderEnum.ASC);
                getSortType(sortingOrderEnum.ASC);
                break;
        }
    };
    const renderSortingIcon = () => {
        if (sortType === sortingOrderEnum.ASC) {
            return _jsx(Icons.SortAmountDown, { "data-testid": 'table-sort-a-z', className: classes.sortIcon }, void 0);
        }
        if (sortType === sortingOrderEnum.DESC) {
            return _jsx(Icons.SortAmounUp, { "data-testid": 'table-sort-z-a', className: classes.sortIcon }, void 0);
        }
        return _jsx(Icons.SortAmountDown, { "data-testid": 'table-sort-no-sort', className: cx(classes.sortIcon, classes.noSort) }, void 0);
    };
    return (_jsxs("button", Object.assign({ onClick: handleClick, "data-testid": testId, className: cx(classes.sortingBtn, { [classes.activeColumn]: sortType !== null }) }, { children: [_jsx(Body, Object.assign({ component: 'span', variant: 'medium-700' }, { children: title }), void 0), renderSortingIcon()] }), void 0));
};
