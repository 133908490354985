export const Sizes = {
    small: 'small',
    medium: 'medium',
    large: 'large',
};
export const Variants = {
    fixedIcon: 'fixedIcon',
    fixedLabel: 'fixedLabel',
    autoWidth: 'autoWidth',
};
export const Colors = {
    primary: 'primary',
    secondary: 'secondary',
    ghost: 'ghost',
};
