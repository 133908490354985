import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { eventTypeIconEnum, eventTypeColorCodeEnum } from '@jobdone/jobdone-shared-library';
import useStyles from './EventEntry.style';
import { Caption, Text } from '../../../Typography';
import { EditAlt, MoreHorizontal, Trash, Event, Holiday, BDay, DogBalloon, HandshakeSolid, TrophySolid, MartiniGlassCitrusSolid, PeopleGroupSolid, PersonChalkboardSolid, Presentation, ArrowUpRight, } from '../../../icons';
import { getAbbreviatedName, useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems, eventMenuItems } from '../..';
import { rangePickerVariantEnum } from '../../../../utils';
import { Tooltip } from '../../../Tooltip';
export const EventEntry = ({ title = 'Event 1', time = 'All Day', color = eventTypeColorCodeEnum.GREEN, className, icon = eventTypeIconEnum.EVENT, onEditClick, onDeleteClick, menuItems, hasFlash, showHoverActions = true, selected, isFlashMode = false, labels = {
    deleteLabel: 'Delete',
}, view = rangePickerVariantEnum['1_WEEK'], isCompact = false, entryType, blockedTitle, ...rest }) => {
    const isBlocked = entryType === 'isBlocked';
    const classes = useStyles({
        color, hasFlash, selected, isFlashMode, isBlocked,
    });
    const { handleClose: handleCloseMore, anchorEl, handleClick: handleClickMore } = useDropdownMenu();
    const [eventFromTime, eventToTime] = time.split(' - ');
    const eventFromTimeRawHour = eventFromTime.split(':')[0];
    const eventFromTimeHour = eventFromTimeRawHour[0] === '0' ? eventFromTimeRawHour[1] : eventFromTimeRawHour;
    const eventFromTimeMinute = eventFromTime?.split(':')[1];
    const eventToTimeRawHour = eventToTime?.split(':')?.[0];
    const eventToTimeHour = eventToTimeRawHour?.[0] === '0' ? eventToTimeRawHour[1] : eventToTimeRawHour;
    const eventToTimeMinute = eventToTime?.split(':')[1];
    const combineMenuItems = menuItems?.map((menuItem) => ({
        ...eventMenuItems.find(({ key }) => key === menuItem.key), ...menuItem,
    })) || [];
    const cantRenderOverlay = !showHoverActions;
    const renderOneWeekOverlay = () => {
        if (cantRenderOverlay)
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneWeekOverlay, {
                        [classes.compactViewOverlayButton]: isCompact,
                    }), onClick: onDeleteClick }, { children: [_jsx(Trash, { className: classes.trashIcon }, void 0), _jsx(Text, Object.assign({ className: classes.deleteLabel }, { children: labels.deleteLabel }), void 0)] }), void 0) }, void 0));
        }
        return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneWeekOverlay, {
                    [classes.compactViewOverlayButton]: isCompact,
                }) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: classes.overlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), combineMenuItems.length ? (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ onClick: handleClickMore, className: classes.overlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems }, void 0)] }, void 0)) : null] }), void 0) }, void 0));
    };
    const renderTwoWeeksOverlay = () => {
        if (cantRenderOverlay)
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.twoWeeksOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.twoWeeksOverlay) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(EditAlt, {}, void 0) }), void 0), combineMenuItems.length ? (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ onClick: handleClickMore, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems }, void 0)] }, void 0)) : null] }), void 0) }, void 0));
    };
    const renderOneMonthOverlay = () => {
        if (cantRenderOverlay)
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneMonthOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneMonthOverlay) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: classes.oneMonthOverlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), combineMenuItems.length ? (_jsxs(_Fragment, { children: [_jsx("button", Object.assign({ onClick: handleClickMore, className: classes.oneMonthOverlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems }, void 0)] }, void 0)) : null] }), void 0) }, void 0));
    };
    const renderEventIcon = () => {
        switch (icon) {
            case eventTypeIconEnum.EVENT:
                return _jsx(Event, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.HOLIDAY:
                return _jsx(Holiday, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.BIRTHDAY:
                return _jsx(BDay, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.DOG_BALLOON:
                return _jsx(DogBalloon, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.HANDSHAKE:
                return _jsx(HandshakeSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.TROPHY:
                return _jsx(TrophySolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.MARTINI_GLASS_CITRUS:
                return _jsx(MartiniGlassCitrusSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.PEOPLE_GROUP:
                return _jsx(PeopleGroupSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.PERSON_CHALKBOARD:
                return _jsx(PersonChalkboardSolid, { className: classes.eventIcon }, void 0);
            case eventTypeIconEnum.PRESENTATION:
                return _jsx(Presentation, { className: classes.eventIcon }, void 0);
            default:
                return null;
        }
    };
    const renderOneWeekContent = () => {
        const renderContent = () => {
            if (isCompact) {
                return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [_jsxs("div", Object.assign({ className: classes.compactViewIconAndTime }, { children: [isBlocked
                                        ? _jsx(ArrowUpRight, { className: classes.compactViewBlockedIcon }, void 0)
                                        : renderEventIcon(), _jsx("div", Object.assign({ className: classes.compactTimeContainer }, { children: !eventToTime
                                            ? (_jsx(Caption, Object.assign({ variant: 'regular', className: classes.compactViewEventName }, { children: getAbbreviatedName(eventFromTime) }), void 0))
                                            : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: eventFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: eventFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: eventToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: eventToTimeMinute }), void 0)] }), void 0)] }, void 0)) }), void 0)] }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.eventNameBlock, classes.compactViewEventName), weight: 500 }, { children: title }), void 0)] }), void 0) }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer) }, { children: [renderEventIcon(), _jsx(Caption, Object.assign({ variant: 'regular' }, { children: time }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneWeekBottomContainer) }, { children: [_jsx(Caption, Object.assign({ className: classes.eventNameBlock, variant: 'regular' }, { children: title }), void 0), isBlocked && _jsx(ArrowUpRight, { className: classes.eventIcon }, void 0)] }), void 0)] }, void 0));
        };
        return (_jsx("div", Object.assign({}, rest, { className: cx(classes.container, classes.oneWeekContainer, {
                [classes.isHover]: !cantRenderOverlay,
                [classes.isBlocked]: isBlocked,
                [classes.flash]: hasFlash,
                [classes.selected]: selected,
                [classes.isFlashMode]: isFlashMode,
                [`${String(className)}`]: !!className,
            }) }, { children: selected
                ? (_jsxs("div", Object.assign({ className: classes.selectedContainer }, { children: [renderContent(), renderOverlay()] }), void 0))
                : (_jsxs(_Fragment, { children: [renderContent(), renderOverlay()] }, void 0)) }), void 0));
    };
    const renderTwoWeeksContent = () => (_jsxs("div", Object.assign({}, rest, { className: cx(classes.container, classes.twoWeeksContainer, {
            [classes.isHover]: !cantRenderOverlay,
            [classes.isBlocked]: isBlocked,
            [classes.flash]: hasFlash,
            [classes.selected]: selected,
            [classes.isFlashMode]: isFlashMode,
            [`${String(className)}`]: !!className,
        }) }, { children: [renderOverlay(), _jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.twoWeeksTopContainer) }, { children: [isBlocked
                        ? _jsx(ArrowUpRight, { className: classes.eventIcon }, void 0)
                        : renderEventIcon(), _jsx(Caption, Object.assign({ className: cx(classes.eventNameBlock, classes.twoWeekViewEventNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.twoWeeksBottomContainer) }, { children: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: eventFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: eventFromTimeMinute }), void 0)] }), void 0), eventToTimeMinute && (_jsxs(_Fragment, { children: ["-", _jsxs("div", Object.assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: eventToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: eventToTimeMinute }), void 0)] }), void 0)] }, void 0))] }, void 0) }), void 0)] }), void 0));
    const renderOneMonthContent = () => {
        const renderContent = () => {
            if (isCompact) {
                return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: _jsx("div", Object.assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsx("div", Object.assign({ className: classes.compactTimeEntry }, { children: !eventFromTimeMinute
                                        ? (_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: getAbbreviatedName(eventFromTimeHour) }), void 0))
                                        : (_jsxs(_Fragment, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: eventFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: eventFromTimeMinute }), void 0)] }, void 0)) }), void 0) }), void 0) }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: _jsx(Caption, Object.assign({ className: cx(classes.eventNameBlock, classes.oneMonthEventNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0), isBlocked && (_jsx(ArrowUpRight, { className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer) }, { children: isBlocked
                            ? _jsx(ArrowUpRight, { className: classes.eventIcon }, void 0)
                            : renderEventIcon() }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer) }, { children: _jsx(Caption, Object.assign({ className: classes.eventNameBlock, variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0)] }, void 0));
        };
        return (_jsxs("div", Object.assign({}, rest, { className: cx(classes.container, classes.oneMonthContainer, {
                [classes.isHover]: showHoverActions,
                [classes.isBlocked]: isBlocked,
                [classes.flash]: hasFlash,
                [classes.selected]: selected,
                [classes.isFlashMode]: isFlashMode,
                [`${String(className)}`]: !!className,
            }) }, { children: [renderOverlay(), renderContent()] }), void 0));
    };
    const renderOverlay = () => {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekOverlay();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksOverlay();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthOverlay();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    const renderContent = () => {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekContent();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksContent();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthContent();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    if (isBlocked && blockedTitle) {
        return (_jsx(Tooltip, Object.assign({ title: blockedTitle }, { children: renderContent() }), void 0));
    }
    return renderContent();
};
