import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        '& > span': {
            color: '#1FA5FF',
        },
    },
    name: {
        maxWidth: 120,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    nameAndCustomId: {
        display: 'flex',
        flexDirection: 'column',
    },
    customId: {
        color: theme.palette.grey[500],
        maxWidth: 120,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));
export default useStyles;
