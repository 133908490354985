import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng, } from 'use-places-autocomplete';
import Autocomplete from '../Autocomplete';
const useStyles = makeStyles(() => ({
    search: {
        marginBottom: 8,
    },
}));
export const AutoComplete = ({ getAddressDetails, center, locale, hiddenLabel, defaultValue = '', disabled, debounce = 400, hasError, }) => {
    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions, } = usePlacesAutocomplete({
        requestOptions: {
            location: new google.maps.LatLng(center.lat, center.lng),
            radius: 100 * 1000,
        },
        debounce,
        defaultValue,
    });
    const classes = useStyles();
    const findAddressDetails = async (address) => {
        try {
            const addressComponents = {
                country: '',
                city: '',
                addressLine1: '',
                zip: '',
                longitude: 0,
                latitude: 0,
                state: '',
                formattedAddress: address,
            };
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            addressComponents.latitude = lat;
            addressComponents.longitude = lng;
            results[0].address_components?.forEach((addressComponent) => {
                if (addressComponent.types.includes('country')) {
                    addressComponents.country = addressComponent.short_name;
                }
                if (addressComponent.types.includes('locality')) {
                    addressComponents.city = addressComponent.long_name;
                }
                if (addressComponent.types.includes('administrative_area_level_1')) {
                    addressComponents.state = addressComponent.long_name;
                }
                if (addressComponent.types.includes('route')) {
                    addressComponents.addressLine1 = addressComponent.long_name;
                }
                if (addressComponent.types.includes('street_number')) {
                    addressComponents.addressLine1 += ` ${addressComponent.long_name}`;
                }
                if (addressComponent.types.includes('postal_code')) {
                    addressComponents.zip = addressComponent.short_name;
                }
            });
            getAddressDetails?.(addressComponents);
        }
        catch (error) {
            console.log('Error: ', error);
        }
    };
    const handleSelect = async (address) => {
        clearSuggestions();
        if (!address?.value)
            return;
        await findAddressDetails(address.value);
    };
    useEffect(() => {
        if (!defaultValue) {
            return;
        }
        findAddressDetails(defaultValue).catch((e) => {
            console.log(e);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);
    const suggestionOptions = status === 'OK' ? data.map(({ description }) => ({
        value: description,
        label: description,
    })) : [];
    return (_jsx("div", Object.assign({ className: classes.search }, { children: _jsx(Autocomplete, { disabled: !ready || !!disabled, "aria-label": 'address', defaultValue: {
                value,
                label: value,
            }, onChange: (e) => {
                if (e.target.value === '') {
                    getAddressDetails?.({
                        country: '',
                        city: '',
                        addressLine1: '',
                        zip: '',
                        longitude: 0,
                        latitude: 0,
                        state: '',
                        formattedAddress: '',
                    });
                }
                setValue(e.target.value);
            }, options: suggestionOptions, onSelectOption: handleSelect, hiddenLabel: hiddenLabel, locale: locale, error: !!hasError }, void 0) }), void 0));
};
