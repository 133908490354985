import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
import { Text } from './Text';
const Variants = {
    regular: 'regular',
    bold: 'bold',
};
const Caption = ({ children, variant, className, component, ...rest }) => {
    const styles = useStyles();
    const classNames = cx(className, {
        [styles.captionRegular]: variant === Variants.regular,
        [styles.captionBold]: variant === Variants.bold,
    });
    return (_jsx(Text, Object.assign({}, rest, { component: component || 'span', className: classNames }, { children: children }), void 0));
};
export default Caption;
