import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Drawer as MUIDrawer, } from '@material-ui/core';
import cx from 'classnames';
import { createContext, useContext } from 'react';
import { useDropdownMenu } from '../../utils/helpers';
import { Button } from '../Button';
import { MoreHorizontal } from '../icons';
import CloseIcon from '../icons/Close';
import { Body, Header } from '../Typography';
import { DrawerMenuItems } from './MenuOptions';
import useStyles from './styles/Drawer.styles';
const DrawerContext = createContext(null);
export const Drawer = ({ children, className, open, onClose, discardButtonProps, saveButtonProps, publishButtonProps, position = 'right', hasFooter = true, size = 'large', showMenuActions, menuItems, footerChildren, footerClassName, showMenuItemsAndCloseButton, ...props }) => {
    const styles = useStyles({
        saveButtonProps: !!saveButtonProps,
        size,
    });
    return (_jsx(DrawerContext.Provider, Object.assign({ value: {
            open,
            onClose,
            hasFooter,
            size,
            showMenuActions,
            menuItems,
            showMenuItemsAndCloseButton,
        } }, { children: _jsxs(MUIDrawer, Object.assign({}, props, { open: open, onClose: onClose, anchor: position, className: cx(styles.drawerStyles, className) }, { children: [_jsx("div", Object.assign({ className: styles.container }, { children: children }), void 0), hasFooter && (_jsxs("div", Object.assign({ className: cx(styles.footerContainer, footerClassName) }, { children: [footerChildren, !!discardButtonProps && (_jsx(Button, Object.assign({ color: 'ghost', size: 'large' }, discardButtonProps), void 0)), _jsxs("div", { children: [!!saveButtonProps && (_jsx(Button, Object.assign({ color: 'secondary', size: 'large', className: styles.saveButton }, saveButtonProps), void 0)), !!publishButtonProps && (_jsx(Button, Object.assign({ size: 'large' }, publishButtonProps), void 0))] }, void 0)] }), void 0))] }), void 0) }), void 0));
};
Drawer.Sidebar = function Sidebar({ children, title, icon, className, showMenuActions, menuItems, ...props }) {
    const styles = useStyles({});
    const { handleClose: handleCloseMore, anchorEl, handleClick: handleClickMore, } = useDropdownMenu();
    return (_jsxs("div", Object.assign({}, props, { className: cx(styles.navigationSection, className) }, { children: [_jsxs("div", Object.assign({ className: styles.sidebarHeader }, { children: [_jsxs("div", Object.assign({ className: styles.sidebarTitle }, { children: [icon && _jsx("span", Object.assign({ className: styles.icon }, { children: icon }), void 0), _jsx(Header, Object.assign({ variant: 'h1', weight: 700 }, { children: title }), void 0)] }), void 0), showMenuActions && (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', className: styles.drawerMenuActions, onClick: handleClickMore, icon: _jsx(MoreHorizontal, {}, void 0) }, void 0)), _jsx(DrawerMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: menuItems }, void 0)] }), void 0), _jsx(_Fragment, { children: children }, void 0)] }), void 0));
};
Drawer.Main = function Main({ children, title, subtitle, className, ...props }) {
    const drawerContext = useContext(DrawerContext);
    const styles = useStyles({
        hasFooter: drawerContext?.hasFooter,
        size: drawerContext?.size,
    });
    const { handleClose: handleCloseMore, anchorEl, handleClick: handleClickMore, } = useDropdownMenu();
    const getMainSectionHeaderButton = () => {
        if (drawerContext?.showMenuActions && drawerContext?.showMenuItemsAndCloseButton) {
            return (_jsxs("div", Object.assign({ className: styles.drawerMenuActionsAndClose }, { children: [_jsx(Button, { variant: 'fixedIcon', color: 'ghost', className: styles.drawerMenuActions, onClick: handleClickMore, icon: _jsx(MoreHorizontal, {}, void 0) }, void 0), _jsx(Button, { variant: 'fixedIcon', color: 'ghost', onClick: () => drawerContext?.onClose?.({}, 'escapeKeyDown'), icon: (_jsx(CloseIcon, { "aria-label": 'drawer-close-icon', className: styles.closeIcon }, void 0)) }, void 0)] }), void 0));
        }
        if (drawerContext?.showMenuActions) {
            return (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', className: styles.drawerMenuActions, onClick: handleClickMore, icon: _jsx(MoreHorizontal, {}, void 0) }, void 0));
        }
        return (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', onClick: () => drawerContext?.onClose?.({}, 'escapeKeyDown'), icon: (_jsx(CloseIcon, { "aria-label": 'drawer-close-icon', className: styles.closeIcon }, void 0)) }, void 0));
    };
    return (_jsxs("div", Object.assign({}, props, { className: cx(styles.mainSection, className), id: 'mainSection' }, { children: [_jsxs("div", Object.assign({ className: styles.mainTitle }, { children: [_jsxs("div", { children: [_jsx(Header, Object.assign({ variant: 'h1', weight: 700, id: 'mainHeader' }, { children: title }), void 0), subtitle && (_jsx(Body, Object.assign({ variant: 'medium', weight: 500, id: 'mainSubHeader', className: styles.subtitle }, { children: subtitle }), void 0))] }, void 0), getMainSectionHeaderButton(), _jsx(DrawerMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: drawerContext?.menuItems }, void 0)] }), void 0), _jsx(_Fragment, { children: children }, void 0)] }), void 0));
};
