import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { DropdownMenu } from '../../DropdownMenu';
import { Body } from '../../Typography';
const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        gap: 10,
        color: theme.palette.grey[500],
        '&:hover svg': {
            color: theme.palette.grey[500],
        },
        '&:hover p': {
            color: theme.palette.primary.main,
        },
    },
    publishItem: {
        '& > *': {
            color: `${theme.palette.primary.main} !important`,
            fontWeight: 700,
        },
        '&:hover svg': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    saveAsTemplateItem: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    convertToAbsenceItem: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    deleteItem: {
        '& > *': {
            color: `${theme.palette.error.main} !important`,
            fontWeight: 500,
        },
        '&:hover svg': {
            color: `${theme.palette.error.main} !important`,
        },
        '&:hover': {
            backgroundColor: '#FFF6F5 !important',
        },
    },
    createAbsenceItem: {
        color: theme.palette.error.main,
        '&:hover svg': {
            color: theme.palette.error.main,
        },
    },
    createShiftItem: {
        color: theme.palette.primary.main,
        '&:hover svg': {
            color: theme.palette.primary.main,
        },
    },
}));
export const EntryMenuItems = ({ anchorEl, handleClose, menuItems }) => {
    const classes = useStyles();
    return (_jsx(DropdownMenu, Object.assign({ "aria-label": 'entry-menu-items', anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems?.map(({ key, label, icon, ...rest }) => (_jsxs(DropdownMenu.Item, Object.assign({ className: cx(classes.menuItem, classes[key]) }, rest, { children: [icon || _jsx(_Fragment, {}, void 0), _jsx(Body, Object.assign({ variant: 'large' }, { children: label }), void 0)] }), key))) }), void 0));
};
