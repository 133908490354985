import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
const useStyles = makeStyles(() => ({
    container: {
        transition: 'all ease .3s',
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0px 16px 32px rgba(22, 36, 61, 0.08)',
        },
    },
}));
const Card = ({ children, width, height, borderRadius, style, className, id = 'card', ...rest }) => {
    const classes = useStyles();
    return (_jsx("div", Object.assign({ style: {
            width, height, borderRadius, ...style,
        }, id: id, className: cx(className, classes.container) }, rest, { children: children }), void 0));
};
export default Card;
