import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { loginStatusEnum } from '@jobdone/jobdone-shared-library';
import cx from 'classnames';
import { theme } from '../../../utils';
import { Tag } from '../../Tag';
import { DropdownMenu } from '../../DropdownMenu';
import { TableTagActionTypeEnum } from '../../../interfaces';
const useStyles = makeStyles(() => ({
    tag: {
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 8,
        padding: '5px 10px',
        height: 34,
    },
    removeAction: {
        color: theme.palette.error.main,
        '&:hover': {
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.light,
        },
    },
    addAction: {
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    menuItem: {
        '&:not(:last-child)::after': {
            position: 'absolute',
            content: '""',
            bottom: 0,
            left: 0,
            height: 1,
            width: '100%',
            backgroundColor: theme.palette.grey[300],
        },
    },
}));
export const TableTag = ({ variant, name, menuItems = [{ value: 'Invite', onClick: () => console.log('Action 1') }], }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    const renderContent = () => {
        const inviteType = () => {
            if (variant === loginStatusEnum.NOT_INVITED) {
                return (_jsx(Tag, Object.assign({ className: classes.tag, withDropdownIcon: true, backgroundColor: theme.palette.grey[100], color: theme.palette.grey[700], onClick: handleClick }, { children: name }), void 0));
            }
            if (variant === loginStatusEnum.INVITATION_SENT) {
                return (_jsx(Tag, Object.assign({ className: classes.tag, withDropdownIcon: true, backgroundColor: theme.palette.secondary.dark, color: theme.palette.primary.main, onClick: handleClick }, { children: name }), void 0));
            }
            if (variant === loginStatusEnum.LOGIN_CONNECTED) {
                return (_jsx(Tag, Object.assign({ withDropdownIcon: true, className: classes.tag, backgroundColor: '#E7FFF8', color: '#0E956F', onClick: handleClick }, { children: name }), void 0));
            }
            return (_jsx(Tag, Object.assign({ className: classes.tag, withDropdownIcon: true, backgroundColor: theme.palette.error.light, color: theme.palette.error.main, onClick: handleClick }, { children: name }), void 0));
        };
        return (_jsxs(_Fragment, { children: [inviteType(), _jsx(DropdownMenu, Object.assign({ anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems?.map(({ value, onClick, actionColor, }) => (_jsx(DropdownMenu.Item, Object.assign({ onClick: onClick, className: cx(classes.menuItem, {
                            [classes.removeAction]: actionColor === TableTagActionTypeEnum.REMOVE,
                            [classes.addAction]: actionColor === TableTagActionTypeEnum.ADD,
                        }) }, { children: value }), String(value)))) }), void 0)] }, void 0));
    };
    return renderContent();
};
