import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Avatar } from '../Avatar';
import { Body } from '../Typography';
const useStyles = makeStyles((theme) => ({
    container: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
        display: 'flex',
        gap: 16,
    },
    description: {
        display: 'block',
        marginTop: 4,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        color: theme.palette.grey[700],
        '&>b': {
            color: theme.palette.common.black,
        },
    },
}));
const Birthday = ({ style, className, title, description, src, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ style: style, className: cx(classes.container, className) }, rest, { children: [_jsx(Avatar, { size: 'l', src: src, placeHolder: title }, void 0), _jsxs("div", { children: [_jsx(Body, Object.assign({ variant: 'medium-700' }, { children: title }), void 0), _jsx("span", { className: classes.description, dangerouslySetInnerHTML: { __html: description } }, void 0)] }, void 0)] }), void 0));
};
export default Birthday;
