import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-param-reassign */
import { ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiPicker from 'emoji-picker-react';
import cx from 'classnames';
import Linkify from 'linkify-react';
import { useEffect, useRef, useState, } from 'react';
import { Avatar, Icons } from '../..';
import { Button } from '../Button';
import { Caption } from '../Typography';
import { theme } from '../../utils';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        '&:hover #replyBtn': {
            display: 'flex',
        },
        '&:hover #reactionBtn': {
            display: 'flex',
        },
    },
    senderRoot: {
        flexDirection: 'row-reverse',
        '&:hover #editBtn': {
            display: 'flex',
        },
        '&:hover #deleteBtn': {
            display: 'flex',
        },
    },
    container: {
        padding: '8px 16px',
        maxWidth: 440,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    avatar: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    inputContainer: {
        padding: '8px',
        width: '100%',
        maxWidth: 440,
        wordBreak: 'break-word',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
    },
    sender: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '16px 16px 0px 16px',
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        '& > a': {
            color: theme.palette.common.white,
        },
    },
    others: {
        backgroundColor: theme.palette.grey[100],
        borderRadius: '16px 16px 16px 0px',
        color: theme.palette.common.black,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        '& > a': {
            color: theme.palette.primary.main,
        },
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        backgroundColor: theme.palette.grey[100],
        borderRadius: '16px 16px 16px 0px',
        color: theme.palette.common.black,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
        '& > a': {
            color: theme.palette.primary.main,
        },
    },
    time: {
        color: theme.palette.grey[500],
    },
    icon: {
        color: theme.palette.grey[500],
        '&#deleteBtn': {
            display: 'none',
        },
        '&#editBtn': {
            display: 'none',
        },
        '&#replyBtn': {
            display: 'none',
        },
        '&#reactionBtn': {
            display: 'none',
        },
    },
    input: {
        resize: 'none',
        overflow: 'hidden',
        backgroundColor: '#DAECFF',
        border: '2px solid #7CA6F4E5',
        padding: '8px 36px 8px 8px',
        borderRadius: 12,
        '&:focus': {
            outline: 'none',
        },
        fontFamily: theme.typography.fontFamily,
        height: 'auto',
        width: '100%',
        color: theme.palette.common.black,
    },
    emoji: {
        fontSize: 40,
        lineHeight: '48px',
    },
    gif: {
        backgroundColor: 'transparent',
        borderRadius: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    reactionsBtn: {
        padding: 6,
        position: 'absolute',
        top: 12,
        right: 10,
        color: theme.palette.grey[500],
        transition: '0.2s',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    emojiPickerContainer: {
        position: 'absolute',
        right: 0,
        top: -460,
        zIndex: 10,
    },
    forwarded: {
        display: 'flex',
        flexDirection: 'column',
    },
    authorLeft: {
        color: theme.palette.primary.main,
    },
    author: {
        color: theme.palette.common.white,
    },
}));
const Bubble = ({ children, style, className, type, time, isEditMode, setEditMode, showEdit, showDelete, onDelete, onEdit, onReply, userAvatar, userName, isEdited, hasOnlyEmoji, hideBackground, isUnread, chatRoomType, openReadMessageStatusDrawer, onAddReaction, locales = {
    edited: 'edited',
    forwarded: 'forwarded',
}, isForwarded, ...rest }) => {
    const classes = useStyles();
    const textRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const [isEmojiOpen, setIsEmojiOpen] = useState(false);
    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.height = 'auto';
            const { scrollHeight } = textRef.current;
            textRef.current.style.height = `${scrollHeight + 4}px`;
        }
    }, [inputValue]);
    const linkifyOptions = {
        target: '_blank',
        rel: 'noopener noreferrer',
    };
    const onEmojiClick = (emojiData) => {
        setInputValue((prev) => prev + emojiData.emoji);
    };
    return (_jsxs("div", Object.assign({ className: cx(className, classes.root, {
            [classes.senderRoot]: type === 'sender',
        }), style: style }, rest, { children: [type === 'group' && _jsx(Avatar, { containerClassName: classes.avatar, size: 's', src: userAvatar, placeHolder: userName, alt: userName }, void 0), !isEditMode ? (_jsxs("div", Object.assign({ className: cx(classes.container, {
                    [classes.sender]: type === 'sender',
                    [classes.others]: type === 'others',
                    [classes.forwarded]: isForwarded,
                    [classes.group]: type === 'group',
                    [classes.gif]: hideBackground,
                }) }, { children: [isForwarded && (_jsxs(Caption, Object.assign({ weight: 700, className: cx({
                            [classes.authorLeft]: type !== 'sender',
                            [classes.author]: type === 'sender',
                        }) }, { children: [_jsx(Icons.ForwardFill, { className: cx({
                                    [classes.authorLeft]: type !== 'sender',
                                    [classes.author]: type === 'sender',
                                }) }, void 0), _jsx(Caption, Object.assign({ weight: 500 }, { children: `  ${locales.forwarded || ''}: ` }), void 0), `${userName || ''}`] }), void 0)), type === 'group' && !isForwarded && (_jsx(Caption, Object.assign({ weight: 700, className: classes.authorLeft }, { children: userName }), void 0)), typeof children === 'string' ? (_jsx(Linkify, Object.assign({ options: linkifyOptions, className: cx({
                            [classes.emoji]: hasOnlyEmoji,
                        }) }, { children: _jsx(Caption, Object.assign({ weight: 500, className: cx({ [classes.emoji]: hasOnlyEmoji }) }, { children: children }), void 0) }), void 0)) : children] }), void 0)) : (_jsxs("div", Object.assign({ className: cx(classes.inputContainer, classes.sender) }, { children: [_jsx("textarea", { ref: textRef, rows: 1, className: classes.input, value: inputValue, onChange: (e) => setInputValue(e.target.value) }, void 0), _jsx(Icons.Reactions, { className: classes.reactionsBtn, onClick: () => setIsEmojiOpen(!isEmojiOpen) }, void 0), isEmojiOpen && (_jsx(ClickAwayListener, Object.assign({ onClickAway: () => setIsEmojiOpen(false) }, { children: _jsx("div", Object.assign({ className: classes.emojiPickerContainer }, { children: _jsx(EmojiPicker, { previewConfig: {
                                    showPreview: false,
                                }, onEmojiClick: onEmojiClick }, void 0) }), void 0) }), void 0))] }), void 0)), !isEditMode && showDelete && (_jsx(Button, { id: 'deleteBtn', variant: 'fixedIcon', color: 'ghost', size: 'small', icon: _jsx(Icons.Trash, {}, void 0), className: classes.icon, onClick: onDelete }, void 0)), !isEditMode && showEdit && typeof children === 'string' && !isForwarded && (_jsx(Button, { id: 'editBtn', variant: 'fixedIcon', color: 'ghost', size: 'small', icon: _jsx(Icons.EditAlt, {}, void 0), className: classes.icon, onClick: () => {
                    setEditMode && setEditMode(true);
                    setInputValue(children || '');
                } }, void 0)), !isEditMode && typeof children === 'string' && (_jsx(Button, { id: 'replyBtn', variant: 'fixedIcon', color: 'ghost', size: 'small', icon: _jsx(Icons.Reply, {}, void 0), className: classes.icon, onClick: onReply }, void 0)), type !== 'sender' ? (_jsx(Button, { id: 'reactionBtn', className: classes.icon, size: 'small', color: 'ghost', variant: 'fixedIcon', icon: _jsx(Icons.Reaction, { size: 22 }, void 0), onClick: () => onAddReaction && onAddReaction() }, void 0)) : null, isEditMode && (_jsx(Button, { variant: 'fixedIcon', icon: _jsx(Icons.Check, {}, void 0), size: 'small', disabled: !inputValue, onClick: () => onEdit && onEdit(inputValue) }, void 0)), isEditMode && (_jsx(Button, { variant: 'fixedIcon', color: 'ghost', icon: _jsx(Icons.Close, {}, void 0), size: 'small', className: classes.icon, onClick: () => {
                    setEditMode && setEditMode(false);
                    setInputValue('');
                } }, void 0)), _jsx(Button, { variant: 'fixedIcon', disabled: chatRoomType !== 'GROUP_CHAT', color: 'ghost', icon: isUnread ? (_jsx(Icons.Check, { style: { color: theme.palette.primary.main } }, void 0)) : (_jsx(Icons.Read, { style: { color: theme.palette.primary.main } }, void 0)), size: 'small', className: classes.icon, onClick: () => {
                    openReadMessageStatusDrawer && openReadMessageStatusDrawer();
                } }, void 0), _jsxs(Caption, Object.assign({ variant: 'bold', weight: 500, className: classes.time }, { children: [` ${time}`, isEdited && ` (${locales.edited || ''})`] }), void 0)] }), void 0));
};
export default Bubble;
