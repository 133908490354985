import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    dropdown: {
        marginTop: 8,
        '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    pickerContainer: {
        display: 'inline-flex',
    },
    calendarRoot: {
        display: 'flex !important',
        width: 116,
        border: 'none !important',
        background: theme.palette.common.white,
        borderRadius: 0,
        userSelect: 'none',
        boxSizing: 'content-box',
        '& > .react-datepicker__time-container': {
            width: '100%',
            '& .react-datepicker__time-box': {
                width: '116px !important',
                margin: '0 !important',
                textAlign: 'left !important',
            },
            '& .react-datepicker__time-list': {
                width: '116px !important',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            },
            '& .react-datepicker__time-list::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
            '& .react-datepicker__time-list-item': {
                padding: '5px 16px !important',
                lineHeight: '30px',
                fontFamily: [
                    'Manrope',
                    '"Segoe UI"',
                    'Tahoma',
                    'Geneva',
                    'Verdana',
                    'sans-serif',
                ].join(','),
                fontStyle: 'normal',
                fontSize: 15,
                fontWeight: '500 !important',
                color: theme.palette.common.black,
            },
            '& .react-datepicker__time-list-item--selected': {
                backgroundColor: `${theme.palette.primary.main} !important`,
            },
            '& > .react-datepicker__header': {
                textAlign: 'left !important',
                padding: '10px 16px !important',
                backgroundColor: theme.palette.common.white,
                borderBottom: 'none',
            },
            '& .react-datepicker-time__header': {
                fontFamily: [
                    'Manrope',
                    '"Segoe UI"',
                    'Tahoma',
                    'Geneva',
                    'Verdana',
                    'sans-serif',
                ].join(','),
                fontSize: '13px',
                lineHeight: '16px',
                color: theme.palette.grey[500],
            },
        },
    },
    firstPickerRoot: {
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
    },
    secondPickerRoot: {
        borderLeft: '2px dotted #DADDE4 !important',
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
    },
    displayNone: {
        display: 'none',
    },
    calendarInput: {
        '& > div > div > input': {
            fontWeight: 500,
        },
        '& > div > div:focus-within': {
            backgroundColor: theme.palette.grey[100],
            border: '2px solid transparent',
        },
        '& > div:focus-within label': {
            color: `${theme.palette.common.black} !important`,
        },
    },
    activeInput: {
        '& > div > div': {
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
            '& svg': {
                color: theme.palette.primary.main,
            },
        },
        '& > div > label': {
            color: theme.palette.primary.main,
        },
    },
    calendarEndAdornment: {
        marginRight: 18,
        '& > svg': {
            color: theme.palette.grey[500],
        },
    },
    timePickerContainer: {
        width: '100%',
    },
    timePickerCalendarRoot: {
        background: theme.palette.common.white,
        boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
        borderRadius: '8px',
    },
}));
