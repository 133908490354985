import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { makeStyles } from '@material-ui/core';
import { Icons, Button } from '../..';
import { Body, Caption } from '../Typography';
const useStyles = makeStyles((theme) => ({
    container: {
        padding: 16,
        backgroundColor: theme.palette.grey[100],
        borderRadius: 16,
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleContainer: {
        display: 'flex',
        gap: 10,
    },
    assignedTeamsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 4,
    },
    assignedLabel: {
        color: theme.palette.grey[500],
    },
    bottomContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 16,
    },
    descriptionContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    description: {
        color: theme.palette.grey[700],
        fontSize: 13,
        lineHeight: '16px',
    },
    deleteButton: {
        color: theme.palette.grey[500],
        marginTop: 4,
        '& svg': {
            width: 20,
            height: 20,
        },
    },
}));
const Event = ({ style, className, icon, title, locale, type, time, ends, onClickEdit, onClickDelete, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ style: style, className: cx(classes.container, className) }, rest, { children: [_jsxs("div", Object.assign({ className: classes.topContainer }, { children: [_jsx("div", { children: _jsxs("div", Object.assign({ className: classes.titleContainer }, { children: [icon, _jsx(Body, Object.assign({ variant: 'large-700' }, { children: title }), void 0)] }), void 0) }, void 0), _jsxs("div", Object.assign({ className: classes.actions }, { children: [onClickEdit && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.EditFilled, {}, void 0), onClick: onClickEdit, size: 'small', variant: 'fixedIcon' }, void 0)), onClickDelete && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.Trash, { className: classes.deleteButton }, void 0), onClick: onClickDelete, size: 'small', variant: 'fixedIcon' }, void 0))] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.bottomContainer }, { children: [_jsxs("div", Object.assign({ className: classes.descriptionContainer }, { children: [_jsx(Caption, Object.assign({ variant: 'bold' }, { children: locale.type }), void 0), _jsx(Body, Object.assign({ variant: 'medium', className: classes.description }, { children: type }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.descriptionContainer }, { children: [_jsx(Caption, Object.assign({ variant: 'bold' }, { children: locale.time }), void 0), _jsx(Body, Object.assign({ variant: 'medium', className: classes.description }, { children: time }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.descriptionContainer }, { children: [_jsx(Caption, Object.assign({ variant: 'bold' }, { children: locale.ends }), void 0), _jsx(Body, Object.assign({ variant: 'medium', className: classes.description }, { children: ends }), void 0)] }), void 0)] }), void 0)] }), void 0));
};
export default Event;
