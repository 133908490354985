import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar } from './Base';
import { Tooltip } from '../Tooltip';
import { Body } from '../Typography';
import useStyles from './styles/AvatarWithTooltipAndName';
export const AvatarWithTooltipAndName = ({ avatar, name, isNameShown = false, avatarSize = 's', customId, }) => {
    const classes = useStyles();
    const renderNameAndCustomId = () => {
        if (isNameShown && customId) {
            return (_jsxs("div", Object.assign({ className: classes.nameAndCustomId }, { children: [_jsx(Body, Object.assign({ className: classes.name, variant: 'medium-700' }, { children: name }), void 0), _jsx(Body, Object.assign({ className: classes.customId, variant: 'medium' }, { children: customId }), void 0)] }), void 0));
        }
        if (isNameShown) {
            return (_jsx(Body, Object.assign({ className: classes.name, variant: 'medium-700' }, { children: name }), void 0));
        }
        return undefined;
    };
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [_jsx(Tooltip, Object.assign({ title: String(name) }, { children: _jsx("div", Object.assign({ className: classes.avatarContainer }, { children: _jsx(Avatar, { size: avatarSize, placeHolder: name, src: avatar || '' }, void 0) }), void 0) }), void 0), renderNameAndCustomId()] }), void 0));
};
