import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { ClickAwayListener } from '@material-ui/core';
import DatePickerComponent from 'react-datepicker';
import { useStyles } from '../styles/RangeTimePicker.styles';
import { getDateFnsLocaleFromJobDoneLocale, JobDoneLocales, useDropdownMenu, } from '../../../utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';
import TimeInput from './TimeInput';
import { DropdownMenu } from '../../DropdownMenu';
const currentDate = new Date();
const startTime = new Date(currentDate.setHours(9, 0, 0, 0));
const endTime = new Date(currentDate.setHours(18, 0, 0, 0));
export const RangeTimePicker = ({ inputClassName, date, getChosenDate = () => { }, inputLocale = {
    errorMessage: '',
    label: '',
    placeholder: '',
}, pickerLocale = {
    start: 'Starts',
    end: 'Ends',
}, locale = JobDoneLocales.ENGLISH, hasError = false, timeFormat = 'HH:mm', ...props }) => {
    const [pickerState, setPickerState] = useState({
        start: date ? dayjs(date.start).set('second', 0).toDate() : startTime,
        end: date ? dayjs(date.end).set('second', 0).toDate() : endTime,
    });
    const { anchorEl, handleClick, handleClose } = useDropdownMenu();
    const styles = useStyles();
    useEffect(() => {
        setPickerState({
            start: date ? dayjs(date.start).set('second', 0).toDate() : startTime,
            end: date ? dayjs(date.end).set('second', 0).toDate() : endTime,
        });
    }, [date]);
    const handleStartTimeChange = (chosenDate) => {
        setPickerState({
            ...pickerState,
            start: chosenDate,
        });
        getChosenDate({
            ...pickerState,
            start: dayjs(chosenDate).set('second', 0).toDate(),
        });
    };
    const handleEndTimeChange = (chosenDate) => {
        let updatedDate;
        const difference = dayjs(chosenDate).diff(dayjs(pickerState.start), 'minutes');
        if (difference === 0 || difference < 0) {
            updatedDate = dayjs(chosenDate).add(1, 'day').toDate();
        }
        else if (difference > 24 * 60) {
            updatedDate = dayjs(chosenDate).subtract(1, 'day').toDate();
        }
        else {
            updatedDate = chosenDate;
        }
        setPickerState({
            ...pickerState,
            end: updatedDate,
        });
        getChosenDate({
            ...pickerState,
            end: dayjs(updatedDate).set('second', 0).toDate(),
        });
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(TimeInput, { className: cx(inputClassName, {
                    [styles.activeInput]: !!anchorEl,
                }), error: hasError, locale: inputLocale, value: pickerState, timeFormat: timeFormat, onClick: handleClick }, void 0), _jsx(DropdownMenu, Object.assign({ className: styles.dropdown, anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: () => { }, transitionDuration: 0 }, { children: _jsx(ClickAwayListener, Object.assign({ onClickAway: handleClose }, { children: _jsxs("div", Object.assign({ className: cx(styles.pickerContainer) }, { children: [_jsx(DatePickerComponent, Object.assign({}, props, { selected: pickerState.start, onChange: handleStartTimeChange, customInput: _jsx("input", { className: styles.displayNone }, void 0), calendarClassName: cx(styles.calendarRoot, styles.firstPickerRoot), showPopperArrow: false, showTimeSelect: true, showTimeSelectOnly: true, timeFormat: timeFormat, timeIntervals: 5, placeholderText: '', timeCaption: pickerLocale.start, locale: getDateFnsLocaleFromJobDoneLocale(locale), inline: true }), void 0), _jsx(DatePickerComponent, Object.assign({}, props, { selected: pickerState.end, onChange: handleEndTimeChange, customInput: _jsx("input", { className: styles.displayNone }, void 0), calendarClassName: cx(styles.calendarRoot, styles.secondPickerRoot), showPopperArrow: false, showTimeSelect: true, showTimeSelectOnly: true, timeFormat: timeFormat, timeIntervals: 5, placeholderText: '', timeCaption: pickerLocale.end, locale: getDateFnsLocaleFromJobDoneLocale(locale), inline: true }), void 0)] }), void 0) }), void 0) }), void 0)] }, void 0));
};
