import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
import { Text } from './Text';
const Variants = {
    h1: 'h1',
    h2: 'h2',
};
const Header = ({ variant = Variants.h2, children, className, component, ...rest }) => {
    const styles = useStyles();
    const baseComponent = typeof variant === 'string' && [Variants.h1, Variants.h2].includes(variant) ? variant : 'h1';
    const classNames = cx(className, {
        [styles.headerH1]: variant === Variants.h1,
        [styles.headerH2]: variant === Variants.h2,
    });
    return (_jsx(Text, Object.assign({}, rest, { component: baseComponent, className: classNames }, { children: children }), void 0));
};
export default Header;
