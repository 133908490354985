import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: [
            'Manrope',
            '"Segoe UI"',
            'Tahoma',
            'Geneva',
            'Verdana',
            'sans-serif',
        ].join(','),
        margin: 0,
        padding: 0,
    },
    HeaderBase: {
        fontWeight: 700,
        lineHeight: '32px',
    },
    headerH1: {
        color: theme.palette.common.black,
        fontWeight: 700,
        lineHeight: '32px',
        fontSize: '24px',
    },
    headerH2: {
        color: theme.palette.common.black,
        fontWeight: 700,
        lineHeight: '32px',
        fontSize: '22px',
    },
    link: {
        color: '#3E7EEC',
        textDecoration: 'underline',
        userSelect: 'none',
        '&:hover': {
            color: '#4589ff',
        },
        '&:active': {
            color: '#3467bf',
        },
    },
    bodySmall: {
        color: theme.palette.common.black,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '20px',
    },
    linkBodySmall: {
        color: '#3E7EEC',
        textDecoration: 'underline',
        userSelect: 'none',
        '&:hover': {
            color: '#4589ff',
        },
        '&:active': {
            color: '#3467bf',
        },
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '20px',
    },
    bodyMedium: {
        color: theme.palette.common.black,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
    },
    linkBodyMedium: {
        color: '#3E7EEC',
        textDecoration: 'underline',
        userSelect: 'none',
        '&:hover': {
            color: '#4589ff',
        },
        '&:active': {
            color: '#3467bf',
        },
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '24px',
    },
    bodyMediumBold: {
        color: theme.palette.common.black,
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    bodyLarge: {
        color: theme.palette.common.black,
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '24px',
    },
    linkBodyLarge: {
        color: '#3E7EEC',
        textDecoration: 'underline',
        userSelect: 'none',
        '&:hover': {
            color: '#4589ff',
        },
        '&:active': {
            color: '#3467bf',
        },
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '24px',
    },
    bodyLargeBold: {
        color: theme.palette.common.black,
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    captionRegular: {
        color: theme.palette.common.black,
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
    },
    captionBold: {
        color: theme.palette.common.black,
        fontSize: '13px',
        lineHeight: '16px',
        extend: 'captionRegular',
        fontWeight: 700,
    },
    subtitleLarge: {
        color: theme.palette.common.black,
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '24px',
    },
}));
export default useStyles;
