import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Input';
import { CalendarFilled } from '../../icons';
import { useInputStyles } from '../styles/DOBPicker.styles';
const DOBInput = forwardRef(({ value, onClick, ...props }, customRef) => {
    const styles = useInputStyles();
    return (_jsx(Input, Object.assign({}, props, { "aria-label": 'DOBInput', value: value, onClick: onClick, onChange: () => { }, ref: customRef, containerClassName: styles.calendarInput, endAdornment: _jsx(CalendarFilled, { className: styles.calendarEndAdornment }, void 0), autoComplete: 'off' }), void 0));
});
DOBInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
};
export default DOBInput;
