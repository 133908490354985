import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import { useEffect, useState } from 'react';
import PhoneNumberInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import useWindowSize from '../../utils/useWindowSize';
import useStyles, { styles } from './styles/PhoneInput.styles';
import { FormControl } from '../FormControl/FormControl';
const PhoneInput = ({ id, error, value, required, onChange, ariaLabel, hiddenLabel, inputClassName, dropdownClassName, containerClassName, dropdownButtonClassName, country = 'us', marginBottom = 0, disabled = false, locale = { label: 'Phone', errorMessage: 'Something wrong happened' }, ...props }) => {
    const [containerWidth, setContainerWidth] = useState(384);
    const [isActive, setIsActive] = useState(false);
    const { width } = useWindowSize();
    const handleFocus = () => {
        setIsActive(true);
    };
    const handeBlur = () => {
        setIsActive(false);
    };
    useEffect(() => {
        if (window) {
            const phoneInputWidth = document.querySelector('.react-tel-input')?.clientWidth;
            if (phoneInputWidth)
                setContainerWidth(phoneInputWidth);
            else
                setContainerWidth(384);
        }
    }, [containerWidth, width]);
    const classes = useStyles({ error: !!error });
    const containerClass = cx(classes.container, containerClassName);
    const dropdownButtonClass = cx(classes.dropdownButton, dropdownButtonClassName);
    const dropdownClass = cx(classes.dropdown, dropdownClassName);
    const inputClass = cx(classes.input, inputClassName, {
        [classes.errorInput]: !!error,
    });
    return (_jsx(FormControl, Object.assign({ id: id, required: required, hiddenLabel: hiddenLabel, active: isActive, label: locale.label, error: error, errorMessage: locale?.errorMessage }, { children: _jsx(PhoneNumberInput, Object.assign({ containerClass: containerClass, containerStyle: styles.containerStyle, inputClass: inputClass, inputStyle: styles.inputStyle, buttonClass: dropdownButtonClass, buttonStyle: styles.dropdownButtonStyles, dropdownClass: dropdownClass, dropdownStyle: { ...styles.dropdownStyles, width: containerWidth }, value: value, disabled: disabled, onBlur: handeBlur, onFocus: handleFocus, onChange: onChange, specialLabel: '', country: country }, props), void 0) }), void 0));
};
export default PhoneInput;
