import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { ShevronRight } from '../icons';
const useStyles = makeStyles((theme) => ({
    container: {
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 8,
        cursor: 'pointer',
        transition: theme.transitions.create(['background', 'color']),
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    label: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '24px',
        color: theme.palette.grey[700],
        '&>b': {
            color: theme.palette.common.black,
        },
    },
    icon: {
        color: theme.palette.grey[500],
    },
}));
const ActionCard = ({ label, style, className, id, onClick, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ style: style, id: id, className: cx(classes.container, className), onClick: onClick, role: 'button', "aria-hidden": true }, rest, { children: [_jsx("span", { className: classes.label, dangerouslySetInnerHTML: { __html: label } }, void 0), _jsx(ShevronRight, { className: classes.icon }, void 0)] }), void 0));
};
export default ActionCard;
