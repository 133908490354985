import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Avatar, Button, Icons, Text, Tag, utils, } from '../..';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 60,
    },
    border: {
        padding: '14px 8px',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 8,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '8px',
    },
    availablity: {
        textAlign: 'center',
    },
    name: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.palette.common.black,
        maxWidth: 150,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    note: {
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        color: theme.palette.grey[700],
        marginTop: 4,
        maxWidth: 150,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    closeButton: {
        color: theme.palette.grey[500],
        marginLeft: 4,
    },
    checkIcon: {
        height: 24,
        fontSize: 20,
        padding: 2,
    },
}));
const EmployeeCard = ({ name, src, position, status, scheduledTime, remainingTime, onClose, style, className, hasBorder, isDisabled, }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: cx(classes.container, className, {
            [classes.border]: hasBorder,
        }), style: style }, { children: [_jsxs("div", Object.assign({ className: classes.info }, { children: [_jsx(Avatar, { placeHolder: name, src: src || '' }, void 0), _jsxs("div", Object.assign({ className: classes.details }, { children: [_jsx(Text, Object.assign({ className: classes.name }, { children: name }), void 0), _jsx(Text, Object.assign({ className: classes.note }, { children: position }), void 0)] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.info }, { children: [_jsxs("div", Object.assign({ className: cx(classes.details, classes.availablity) }, { children: [status ? _jsx(Tag, Object.assign({ color: utils.theme.palette.primary.main, backgroundColor: '#ECF6FF' }, { children: status }), void 0) : _jsx("div", { style: { height: 24 } }, void 0), _jsx(Text, Object.assign({ className: classes.note }, { children: scheduledTime }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: cx(classes.details, classes.availablity) }, { children: [status ? _jsx("span", Object.assign({ className: classes.checkIcon }, { children: _jsx(Icons.ApprovedCircleCheck, {}, void 0) }), void 0) : _jsx("div", { style: { height: 24 } }, void 0), _jsx(Text, Object.assign({ className: classes.note }, { children: remainingTime }), void 0)] }), void 0), onClose && (_jsx(Button, { className: classes.closeButton, onClick: onClose, icon: _jsx(Icons.Close, {}, void 0), color: 'ghost', size: 'small', variant: 'fixedIcon', disabled: isDisabled }, void 0))] }), void 0)] }), void 0));
};
export default EmployeeCard;
