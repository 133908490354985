import { makeStyles } from '@material-ui/core';
const getHeight = (hasFooter) => {
    if (hasFooter)
        return 'calc(100vh - 80px)';
    return '100vh';
};
const getWidth = (size) => {
    if (size === 'large')
        return 448;
    if (size === 'medium')
        return 344;
    if (size === 'small')
        return 240;
    return 448;
};
const useStyles = makeStyles((theme) => ({
    drawerStyles: {
        '& > .MuiDrawer-paperAnchorDockedLeft': {
            border: 'none',
            boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
        },
    },
    container: {
        display: 'flex',
        '& > div + div#mainSection  h1#mainHeader': {
            fontSize: 18,
            lineHeight: '24px',
        },
    },
    navigationSection: {
        width: '344px',
        height: 'calc(100vh - 80px)',
        padding: '28px 32px',
        background: theme.palette.common.white,
    },
    sidebarHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '44px',
    },
    sidebarTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    drawerMenuActions: {
        color: theme.palette.grey[500],
    },
    icon: {
        marginRight: 14,
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
    },
    mainSection: {
        width: (props) => getWidth(props.size),
        height: (props) => getHeight(props.hasFooter),
        boxShadow: (props) => ((props.hasFooter && props.size === 'small') ? 'none' : '3px 12px 32px rgba(22, 36, 61, 0.14)'),
        background: theme.palette.common.white,
        overflowY: 'auto',
        padding: (props) => (props.size === 'small' ? '32px 16px' : '32px'),
    },
    mainTitle: {
        marginBottom: '48px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    subtitle: {
        color: theme.palette.grey[700],
    },
    closeIcon: {
        cursor: 'pointer',
        color: theme.palette.grey[500],
    },
    footerContainer: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: (props) => (props.saveButtonProps ? 'space-between' : 'center'),
        background: theme.palette.common.white,
        height: '80px',
        columnGap: (props) => (props.size === 'small' ? 0 : '32px'),
        width: '100%',
        padding: (props) => (props.size === 'small' ? 0 : '0 32px'),
        '& button': {
            width: '176px',
        },
    },
    saveButton: {
        marginRight: 32,
    },
    drawerMenuActionsAndClose: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 4,
    },
}));
export default useStyles;
