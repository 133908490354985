import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect, } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import cx from 'classnames';
import { getDateFnsLocaleFromJobDoneLocale, getSundayOfWeek, getMondayOfWeek, JobDoneLocales, getSundayOfNextWeek, getEndOfMonth, getEndOfDay, getStartOfDay, getStartOfMonth, getStartOfYear, getEndOfYear, getMaxDate, } from '../../../utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';
import { useStyles } from '../styles/RangePicker.styles';
import Input from './Input';
import { ChoiceTag } from '../../ChoiceTag';
import { rangePickerVariantEnum } from '../../../utils/consts';
export const RangePicker = ({ defaultRangePickerVariantType = rangePickerVariantEnum['1_WEEK'], chosenDatePeriod = {
    from: getMondayOfWeek(getStartOfDay(new Date())),
    to: getSundayOfWeek(getEndOfDay(new Date())),
}, setChosenDatePeriod, locale = JobDoneLocales.ENGLISH, rangeLocale = {
    TODAY: '1 Day',
    '1_WEEK': '1 Week',
    '2_WEEK': '2 Weeks',
    '1_MONTH': '1 Month',
    '1_YEAR': '1 Year',
    '1_QUARTER': '1 Quarter',
    CUSTOM: 'Custom',
}, hideSidebar, hideRangeButtons = [], inline, getRangePickerType, ...rest }) => {
    const classes = useStyles({ inline });
    const ref = useRef(null);
    const [rangePickerVariant, setRangePickerVariant] = useState(defaultRangePickerVariantType);
    const isOneDaySelected = rangePickerVariant === rangePickerVariantEnum.TODAY;
    const isOneWeekSelected = rangePickerVariant === rangePickerVariantEnum['1_WEEK'];
    const isTwoWeekSelected = rangePickerVariant === rangePickerVariantEnum['2_WEEK'];
    const isOneMonthSelected = rangePickerVariant === rangePickerVariantEnum['1_MONTH'];
    const isOneYearSelected = rangePickerVariant === rangePickerVariantEnum['1_YEAR'];
    const isCustomSelected = rangePickerVariant === rangePickerVariantEnum.CUSTOM;
    useEffect(() => {
        if (!isCustomSelected && (chosenDatePeriod.from?.getDate() === chosenDatePeriod.to?.getDate())) {
            setRangePickerVariant(rangePickerVariantEnum.TODAY);
        }
    }, [chosenDatePeriod, isCustomSelected]);
    useEffect(() => {
        getRangePickerType && getRangePickerType(rangePickerVariant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangePickerVariant]);
    const getFromDate = (date) => {
        if (isOneDaySelected) {
            return getStartOfDay(date);
        }
        if (isOneMonthSelected) {
            return getStartOfMonth(date);
        }
        if (isOneYearSelected) {
            return getStartOfYear(date);
        }
        return getStartOfDay(getMondayOfWeek(date));
    };
    const getToDate = (date) => {
        if (isOneWeekSelected) {
            return getEndOfDay(getSundayOfWeek(getMondayOfWeek(date)));
        }
        if (isTwoWeekSelected) {
            return getEndOfDay(getSundayOfNextWeek(getMondayOfWeek(date)));
        }
        if (isOneMonthSelected) {
            return getEndOfMonth(date);
        }
        if (isOneYearSelected) {
            return getEndOfYear(date);
        }
        return getEndOfDay(date);
    };
    const handleDateChange = (chosenDate) => {
        if (Array.isArray(chosenDate)) {
            if (isCustomSelected) {
                const [start, end] = chosenDate;
                const updatedPeriod = {
                    from: start ? getStartOfDay(start) : null,
                    to: end ? getEndOfDay(end) : null,
                };
                setChosenDatePeriod?.(updatedPeriod);
            }
            else {
                const [start] = chosenDate;
                const updatedPeriod = {
                    from: getFromDate(start),
                    to: getToDate(start),
                };
                setChosenDatePeriod?.(updatedPeriod);
            }
        }
        else {
            const updatedPeriod = {
                from: getFromDate(chosenDate),
                to: getToDate(chosenDate),
            };
            setChosenDatePeriod?.(updatedPeriod);
        }
        !isCustomSelected && ref?.current?.setOpen(false);
    };
    const handleRangePickerChange = (shownRangePickerVariant) => {
        if (shownRangePickerVariant !== rangePickerVariantEnum['1_QUARTER']) {
            setRangePickerVariant((prev) => {
                if (prev === shownRangePickerVariant && !hideRangeButtons?.includes(rangePickerVariantEnum.TODAY)) {
                    return rangePickerVariantEnum.TODAY;
                }
                return shownRangePickerVariant;
            });
        }
    };
    useEffect(() => {
        if (isOneDaySelected) {
            const updatedPeriod = {
                from: getStartOfDay(chosenDatePeriod.from),
                to: getEndOfDay(chosenDatePeriod.from),
            };
            setChosenDatePeriod?.(updatedPeriod);
        }
        else if (isOneMonthSelected) {
            const updatedPeriod = {
                from: getStartOfMonth(chosenDatePeriod.from),
                to: getEndOfMonth(chosenDatePeriod.from),
            };
            setChosenDatePeriod?.(updatedPeriod);
        }
        else if (isOneYearSelected) {
            const updatedPeriod = {
                from: getStartOfYear(chosenDatePeriod.from),
                to: getEndOfYear(chosenDatePeriod.from),
            };
            setChosenDatePeriod?.(updatedPeriod);
        }
        else if (isCustomSelected) {
            const updatedPeriod = {
                from: chosenDatePeriod.from,
                to: chosenDatePeriod.to,
            };
            setChosenDatePeriod?.(updatedPeriod);
        }
        else {
            const updatedPeriod = {
                from: getStartOfDay(getMondayOfWeek(chosenDatePeriod.from)),
                to: getToDate(chosenDatePeriod.from),
            };
            setChosenDatePeriod?.(updatedPeriod);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rangePickerVariant]);
    const CalendarContainerComponent = ({ children, ...props }) => {
        if (document) {
            if (rangePickerVariant !== rangePickerVariantEnum.TODAY && rangePickerVariant !== rangePickerVariantEnum.CUSTOM) {
                const monthContainer = document.querySelector('.react-datepicker__month');
                monthContainer?.childNodes.forEach((element) => {
                    if (element.classList.contains(classes.selectedWeek)) {
                        element.classList.remove(classes.selectedWeek);
                    }
                });
                const dayRangeStart = document.querySelector('.react-datepicker__day--range-start');
                const dayRangeEnd = document.querySelector('.react-datepicker__day--range-end');
                dayRangeStart?.parentElement?.classList.add(classes.selectedWeek);
                dayRangeEnd?.parentElement?.classList.add(classes.selectedWeek);
            }
        }
        return (_jsxs(CalendarContainer, Object.assign({}, props, { children: [children, !hideSidebar && (_jsx("div", Object.assign({ className: classes.rangeTypes }, { children: Object.entries(rangeLocale).map(([key, value]) => {
                        if (hideRangeButtons?.includes(key)) {
                            return null;
                        }
                        return (_jsx(ChoiceTag, Object.assign({ selected: key === rangePickerVariant, onClick: () => handleRangePickerChange(key) }, { children: value }), key));
                    }) }), void 0))] }), void 0));
    };
    const renderInputDate = () => {
        if (isOneDaySelected) {
            return 'd MMM, yyyy';
        }
        if (isOneMonthSelected) {
            return 'MMMM yyyy';
        }
        if (isOneYearSelected) {
            return 'yyyy';
        }
        return 'd MMM';
    };
    return (_jsx(DatePicker, Object.assign({}, rest, { inline: inline, startDate: chosenDatePeriod.from, endDate: chosenDatePeriod.to, onChange: handleDateChange, calendarClassName: cx(classes.calendarRoot, {
            [classes.oneWeek]: isOneWeekSelected,
            [classes.twoWeek]: isTwoWeekSelected,
            [classes.custom]: isCustomSelected,
        }), selected: !isCustomSelected ? chosenDatePeriod.from : undefined, selectsRange: isOneWeekSelected || isTwoWeekSelected || isCustomSelected, locale: getDateFnsLocaleFromJobDoneLocale(locale), showMonthYearPicker: isOneMonthSelected, showYearPicker: isOneYearSelected, dateFormat: renderInputDate(), calendarStartDay: 1, customInput: (_jsx(Input, { setChosenDatePeriod: setChosenDatePeriod, chosenDatePeriod: chosenDatePeriod, isOneWeekSelected: isOneWeekSelected, isTwoWeekSelected: isTwoWeekSelected, isOneMonthSelected: isOneMonthSelected, isOneDaySelected: isOneDaySelected, isOneYearSelected: isOneYearSelected, isCustomSelected: isCustomSelected }, void 0)), calendarContainer: CalendarContainerComponent, ref: ref, maxDate: !chosenDatePeriod.to ? getMaxDate(chosenDatePeriod.from) : undefined }), void 0));
};
