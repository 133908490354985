import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import Linkify from 'linkify-react';
import { Avatar } from '../Avatar';
import { Caption } from '../Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    container: {
        width: '100%',
        maxWidth: 680,
        wordBreak: 'break-word',
    },
    messageContainer: {
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: 16,
        backgroundColor: 'white',
        padding: '8px 16px',
        width: '100%',
    },
    timeAndActionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        marginTop: 4,
    },
    group: {
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-word',
        '& > p': {
            color: theme.palette.common.black,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            '& > a': {
                color: theme.palette.primary.main,
            },
        },
    },
    time: {
        color: theme.palette.grey[500],
    },
    avatar: {
        display: 'flex',
        alignSelf: 'flex-start',
    },
}));
const linkifyOptions = {
    target: '_blank',
    rel: 'noopener noreferrer',
};
const Comment = ({ children, style, className, time, userAvatar, userName, actions, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: cx(className, classes.root), style: style }, rest, { children: [_jsx(Avatar, { containerClassName: classes.avatar, size: 's', src: userAvatar, placeHolder: userName, alt: userName }, void 0), _jsxs("div", Object.assign({ className: classes.messageContainer }, { children: [_jsxs("div", Object.assign({ className: cx(classes.container, classes.group) }, { children: [_jsx(Caption, Object.assign({ weight: 700 }, { children: userName }), void 0), _jsx(Linkify, Object.assign({ options: linkifyOptions }, { children: children }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.timeAndActionsContainer }, { children: [_jsx(Caption, Object.assign({ variant: 'bold', weight: 500, className: classes.time }, { children: time }), void 0), actions] }), void 0)] }), void 0)] }), void 0));
};
export default Comment;
