import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
const Weights = {
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500,
    600: 600,
    700: 700,
    800: 800,
    900: 900,
};
const Displays = {
    none: 'none',
    block: 'block',
    inline: 'inline',
    inlineBlock: 'inline-block',
    inherit: 'inherit',
};
export const Text = ({ children, className = '', size, weight, component, display, style = {}, testId = '', ...props }) => {
    const styles = useStyles();
    const Component = component || 'span';
    const classNames = cx(styles.root, className);
    const componentStyle = {
        ...style,
    };
    if (size)
        componentStyle.fontSize = size;
    if (display)
        componentStyle.display = display;
    if (weight)
        componentStyle.fontWeight = weight;
    return (_jsx(Component, Object.assign({}, props, { className: classNames, style: componentStyle, "data-testid": testId }, { children: children }), void 0));
};
