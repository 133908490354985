import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { DropdownMenu } from '../../DropdownMenu';
import { Body } from '../../Typography';
const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        gap: 10,
    },
    blackLabel: {
        '& > *': {
            color: `${theme.palette.common.black} !important`,
            fontWeight: 500,
        },
        '&:hover *': {
            color: `${theme.palette.primary.main} !important`,
        },
    },
    errorLabel: {
        '& > *': {
            color: `${theme.palette.error.main} !important`,
            fontWeight: 500,
        },
        '&:hover': {
            backgroundColor: '#FFF6F5',
        },
    },
    errorLabelWithTopBorder: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        '& > *': {
            color: `${theme.palette.error.main} !important`,
            fontWeight: 500,
        },
        '&:hover': {
            backgroundColor: '#FFF6F5',
        },
    },
}));
export const DrawerMenuItems = ({ anchorEl, handleClose, menuItems }) => {
    const classes = useStyles();
    return (_jsx(DropdownMenu, Object.assign({ "aria-label": 'entry-menu-items', anchorEl: anchorEl, open: Boolean(anchorEl), handleClose: handleClose }, { children: menuItems?.map(({ key, label, icon, style, onClick, }) => (_jsxs(DropdownMenu.Item, Object.assign({ className: cx(classes.menuItem, style && classes[style]), onClick: onClick }, { children: [icon || _jsx(_Fragment, {}, void 0), _jsx(Body, Object.assign({ variant: 'large' }, { children: label }), void 0)] }), key))) }), void 0));
};
