import { makeStyles } from '@material-ui/core';
import { LabelPlacements, Sizes, } from '../conts';
const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: props.size === Sizes.medium ? 52 : 32,
        height: props.size === Sizes.medium ? 32 : 20,
        padding: 0,
        marginRight: props.labelPlacement === LabelPlacements.end ? '14px' : 0,
        marginLeft: props.labelPlacement === LabelPlacements.start ? '14px' : 0,
        marginTop: props.labelPlacement === LabelPlacements.top ? '16px' : 0,
    }),
    switchBase: {
        padding: 2,
        '&$checked': {
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 'none',
            },
        },
    },
    thumb: (props) => ({
        width: props.size === Sizes.medium ? 28 : 16,
        height: props.size === Sizes.medium ? 28 : 16,
    }),
    track: (props) => ({
        borderRadius: 16,
        backgroundColor: props.doubleLabel ? theme.palette.primary.main : theme.palette.grey[400],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    }),
    smallSize: {
        color: 'red',
    },
    checked: (props) => ({
        transform: props.size === Sizes.medium ? 'translateX(20px) !important' : 'translateX(12px) !important',
    }),
    focusVisible: {},
    disabled: {
        color: theme.palette.grey[400],
        '& + $track': {
            backgroundColor: `${theme.palette.grey[100]} !important`,
            border: `2px solid ${theme.palette.grey[300]} !important`,
            opacity: '1 !important',
        },
        '&$checked': {
            color: theme.palette.grey[400],
            opacity: 1,
        },
    },
    label: {
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: '16px',
        color: theme.palette.common.black,
    },
    muiFormLabel: {
        margin: 0,
    },
}));
export default useStyles;
