import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Typography.styles';
import { Text } from './Text';
const Variants = {
    large: 'large',
};
const Subtitle = ({ variant = Variants.large, className = '', children, ...rest }) => {
    const styles = useStyles();
    const classNames = cx(className, {
        [styles.subtitleLarge]: variant === Variants.large,
    });
    return (_jsx(Text, Object.assign({}, rest, { className: classNames }, { children: children }), void 0));
};
export default Subtitle;
