import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/require-default-props */
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { getSafePercent } from './utils';
import useStyles from './styles/ProgressBar.styles';
export const ProgressBar = ({ from = 0, to = 100, delay = 100, unfilledBgColor, filledBgColor, parentClassName, childClassName, height, children, ...props }) => {
    const safePercentOfFrom = getSafePercent(from);
    const safePercentOfTo = getSafePercent(to);
    const [percent, setPercent] = useState(safePercentOfFrom);
    useEffect(() => {
        const timeoutId = setTimeout(() => setPercent(safePercentOfTo), delay);
        return () => clearTimeout(timeoutId);
    }, [safePercentOfFrom, safePercentOfTo, delay]);
    const styles = useStyles();
    const parentClasses = cx(styles.track, parentClassName);
    const childClasses = cx(styles.progress, childClassName);
    return (_jsx("div", Object.assign({ "data-testid": 'track', className: parentClasses, style: { background: unfilledBgColor, height } }, props, { children: _jsx("div", Object.assign({ "data-testid": 'progress', className: childClasses, style: { width: `${percent}%`, background: filledBgColor, height } }, { children: _jsx("div", Object.assign({ className: styles.content }, { children: children }), void 0) }), void 0) }), void 0));
};
