import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { components, } from 'react-select';
import AsyncSelectComponent from 'react-select/async';
import cx from 'classnames';
import { useState } from 'react';
import { Avatar, Body, Icons, Text, Checkbox, } from '../..';
import { useStyles as useDropdownStyles, selectStyles } from './styles/Dropdown.styles';
import { FormControl } from '../FormControl/FormControl';
import useAutocompleteStyles from './styles/Autocomplete.styles';
const useStyles = makeStyles(() => ({
    optionContainer: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    checkIcon: {
        color: 'green',
        right: 10,
        textAlign: 'right',
        marginLeft: 10,
    },
}));
const AsyncSelect = ({ hiddenLabel, locale = {
    label: 'Label', errorMessage: 'Error', placeholder: 'Select...', noOptionMsg: 'No Options',
}, testId = 'async-autocomplete', labelClassName = '', className = '', error = false, labelId = '', loadOptions = () => { }, defaultOptions, required, options, value, withIcon = false, isLoading = false, isDisabled = false, onChange = () => { }, onBlur = () => { }, onFocus = () => { }, onInputChange = () => { }, containerClassname, isClearable = false, isMulti, maxMenuHeight, customSingleValueComponent, ...rest }) => {
    const stylesFromDropdown = useDropdownStyles({});
    const stylesFromAutocomplete = useAutocompleteStyles();
    const [isActive, setIsActive] = useState(false);
    const styles = useStyles();
    const handleFocus = () => {
        setIsActive(true);
    };
    const handeBlur = () => {
        setIsActive(false);
    };
    const renderOption = (option, isSelected) => {
        if (option.isAction) {
            return (_jsxs("div", Object.assign({ className: stylesFromAutocomplete.optionWithIcon }, { children: [option?.icon, _jsx(Text, Object.assign({ "aria-label": 'autocomplete-option-label', size: 15, weight: 700, className: stylesFromAutocomplete.actionOption }, { children: option.label }), void 0)] }), void 0));
        }
        if (withIcon) {
            const avatarProps = {};
            if (option?.src)
                avatarProps.src = option?.src;
            else
                avatarProps.placeHolder = option?.label;
            return (_jsxs("div", Object.assign({ className: stylesFromAutocomplete.optionWithIcon }, { children: [isMulti && _jsx(Checkbox, { checked: isSelected, onChange: () => null }, void 0), _jsxs("div", Object.assign({ className: stylesFromAutocomplete.optionWithIconFlexDiv }, { children: [option?.icon
                                ? option?.icon
                                : _jsx(Avatar, Object.assign({ size: 'm' }, avatarProps, { "aria-label": 'autocomplete-option-avatar' }), void 0), _jsxs("div", Object.assign({ className: stylesFromAutocomplete.optionWithIconTextContainer }, { children: [_jsx(Text, Object.assign({ "aria-label": 'autocomplete-option-label', size: 15, weight: 700 }, { children: option.label }), void 0), option.secondary
                                        && (_jsx(Body, Object.assign({ className: stylesFromAutocomplete.optionWithIconSecondaryText, component: 'span', variant: 'large', weight: 500, "aria-label": 'autocomplete-option-secondary-text' }, { children: option.secondary }), void 0))] }), void 0)] }), void 0), option.tag && option.tag] }), void 0));
        }
        return option.label;
    };
    return (_jsx("div", Object.assign({ className: containerClassname }, { children: _jsx(FormControl, Object.assign({ active: isActive, hiddenLabel: hiddenLabel, error: error, errorMessage: locale.errorMessage, id: `${labelId}-${String(locale?.label)}`, required: required, label: locale?.label }, { children: _jsx(AsyncSelectComponent, Object.assign({}, rest, { cacheOptions: true, maxMenuHeight: maxMenuHeight, className: cx(stylesFromDropdown.select, className), styles: selectStyles, "data-testid": testId, value: value, onChange: onChange, onFocus: handleFocus, onInputChange: onInputChange, onBlur: handeBlur, loadOptions: loadOptions, options: options, isLoading: isLoading, isDisabled: isDisabled, defaultOptions: defaultOptions, isClearable: isClearable, placeholder: locale.placeholder, isMulti: isMulti, components: {
                    SingleValue: (props) => {
                        if (customSingleValueComponent) {
                            return (_jsx(components.SingleValue, Object.assign({}, props, { children: customSingleValueComponent(props) }), void 0));
                        }
                        return _jsx(components.SingleValue, Object.assign({}, props), void 0);
                    },
                    NoOptionsMessage: (props) => (_jsx(components.NoOptionsMessage, Object.assign({}, props, { children: locale.noOptionMsg }), void 0)),
                    DropdownIndicator: (props) => (_jsx(components.DropdownIndicator, Object.assign({}, props, { children: _jsx(Icons.DropdownIcon, { className: stylesFromDropdown.dropdownIndicator }, void 0) }), void 0)),
                    Option: (props) => {
                        const { data, isSelected } = props;
                        return (_jsxs(components.Option, Object.assign({}, props, { className: styles.optionContainer }, { children: [renderOption(data, isSelected), isSelected && !isMulti && _jsx(Icons.GreenCheck, { className: styles.checkIcon }, void 0)] }), void 0));
                    },
                } }), void 0) }), void 0) }), void 0));
};
export default AsyncSelect;
