import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    flexContainer: {
        display: 'flex',
        gap: 8,
    },
    filterLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        color: theme.palette.common.black,
        fontWeight: 500,
        userSelect: 'none',
    },
    active: {
        fontWeight: 700,
        color: `${theme.palette.common.white} !important`,
    },
    selected: {
        fontWeight: 700,
        color: `${theme.palette.primary.main} !important`,
    },
    iconAndCountContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    filterIcon: {
        color: theme.palette.grey[500],
    },
    selectedOptionCount: {
        color: '#206AE9',
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: 8,
        padding: '0 8px',
        minWidth: 24,
    },
    option: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 8,
        fontWeight: 500,
    },
    customRadio: {
        padding: '8px 0 8px 16px',
        fontSize: 14,
    },
    customCheckbox: {
        padding: '8px 8px 8px 2px',
        '& > label': {
            cursor: 'pointer',
            fontSize: 14,
            marginLeft: 6,
        },
    },
    optionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
    },
}));
export default useStyles;
