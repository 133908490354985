import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../utils/shared/themes/JobDoneDefaultTheme';
export const selectStyles = {
    control: (provided, { isDisabled }) => ({
        ...provided,
        display: 'flex',
        alignContent: 'center',
        width: '100%',
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 8,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[100],
        border: isDisabled ? `2px solid ${theme.palette.grey[400]}` : '2px solid transparent',
        transition: theme.transitions.create('all'),
        padding: '0 10px',
        boxShadow: 'none',
        '&::placeholder': {
            color: theme.palette.grey[500],
            opacity: 1,
            verticalAlign: 'middle',
        },
        '&:hover': {
            border: `2px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
        },
        '&:focus-within': {
            color: theme.palette.common.black,
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.common.white,
        },
    }),
    menu: (provided) => ({
        ...provided,
        boxShadow: '3px 12px 32px 0px #16243D24',
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0,
                transform: 'translateY(1rem)',
            },
            '100%': {
                opacity: 1,
                transform: 'translateY(0)',
            },
        },
        animation: theme.transitions.create('fadeIn'),
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
        // eslint-disable-next-line no-nested-ternary
        color: state.isDisabled
            ? theme.palette.grey[400]
            : state.isFocused
                ? theme.palette.primary.main
                : theme.palette.grey[500],
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
        transition: theme.transitions.create('transform'),
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        backgroundColor: state.isFocused
            ? theme.palette.secondary.light
            : 'white',
        color: theme.palette.common.black,
        fontFamily: 'Manrope',
        fontWeight: state.isSelected ? 700 : 500,
        fontSize: 15,
        ':hover': {
            color: theme.palette.primary.light,
            backgroundColor: `${theme.palette.secondary.light} !important`,
        },
        ':active': {
            color: theme.palette.primary.dark,
            backgroundColor: `${theme.palette.secondary.light} !important`,
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        background: theme.palette.grey[300],
        alignItems: 'center',
        borderRadius: '8px',
        padding: '2px 4px 2px 8px',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWweight: 500,
        fontSize: '14px',
        color: theme.palette.common.black,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        width: 18,
        height: 18,
        cursor: 'pointer',
        color: theme.palette.grey[300],
        background: theme.palette.grey[500],
        marginLeft: 4,
        borderRadius: 15,
        ':hover': {
            background: theme.palette.grey[500],
        },
        ':active': {
            background: theme.palette.grey[500],
        },
    }),
    // menuList: (provided: CSSObjectWithLabel, state) => ({
    //   ...provided,
    //   // border: '3px solid red',
    // }),
    // menuPortal: (provided: CSSObjectWithLabel, state) => ({
    //   ...provided,
    //   border: '3px solid red',
    // }),
};
export const useStyles = makeStyles(() => ({
    select: ({ error, itemPerPageSelect }) => ({
        '& > .jobDone-select': {
            '&__menu': {
                border: 'none !important',
            },
            '&__control': {
                // eslint-disable-next-line no-nested-ternary
                border: error
                    ? `2px solid ${theme.palette.error.main} !important`
                    : itemPerPageSelect
                        ? `2px solid ${theme.palette.grey[400]}`
                        : '2px solid transparent',
                '& > .jobDone-select__value-container': {
                    '& > .jobDone-select__single-value': {
                        color: theme.palette.common.black,
                    },
                },
                '& > .jobDone-select__indicators': {
                    width: itemPerPageSelect ? 30 : 'initial',
                },
            },
        },
    }),
    dropdownIndicator: ({ error }) => ({
        display: 'inline-block',
        fontStyle: 'normal',
        textAlign: 'center',
        lineHeight: 0,
        textTransform: 'none',
        color: error ? theme.palette.error.main : 'inherit',
    }),
    label: ({ error }) => ({
        userSelect: 'none',
        marginBottom: '0.8rem',
        fontWeight: 'bold',
        fontSize: 13,
        color: error ? theme.palette.error.main : theme.palette.common.black,
        transition: theme.transitions.create('all'),
    }),
    labelActive: {
        color: `${theme.palette.primary.main} !important`,
    },
    errorMessage: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: 6,
        color: theme.palette.error.main,
        textAlign: 'left',
    },
    selectedOptionCheck: {
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 10,
        textAlign: 'right',
    },
    optionContainer: ({ isMulti }) => ({
        display: 'flex',
        alignItems: 'center',
        flexWrap: isMulti ? 'wrap' : 'nowrap',
    }),
    avatar: {
        marginRight: 8,
    },
    icon: {
        display: 'flex',
        marginRight: 8,
    },
}));
