import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import cx from 'classnames';
import { theme } from '../../utils';
import 'react-circular-progressbar/dist/styles.css';
import { Stop, Start } from '../icons';
import { Loading } from '../..';
const useStyles = makeStyles(() => ({
    smallRoot: {
        width: 56,
    },
    normalSizeRoot: {
        width: 136,
    },
    startWorkInnerCircle: {
        cursor: ({ isDisabled }) => (isDisabled ? 'default' : 'pointer'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '65%',
        height: '65%',
        borderRadius: '100%',
        border: 'none',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
    workIcon: {
        color: theme.palette.common.white,
    },
    startIcon: {
        transform: 'translateX(3px)',
    },
    smallIcon: {
        '& > svg': {
            padding: 10,
        },
    },
    smallStartIcon: {
        transform: 'translateX(2px)',
    },
    pulseStart: {
        border: 0,
        cursor: ({ isDisabled }) => (isDisabled ? 'default' : 'pointer'),
        color: theme.palette.common.white,
        fontSize: 14,
        borderRadius: '100%',
        backgroundColor: theme.palette.primary.main,
        transform: 'scale(1)',
        animation: '$pulse 2s infinite',
        boxShadow: '0 0 0 0 rgba(47, 116, 235, 1)',
    },
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(0.85)',
            boxShadow: '0 0 0 0 rgba(47, 116, 235, 0.7)',
        },
        '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 10px rgba(47, 116, 235, 0)',
        },
        '100%': {
            transform: 'scale(0.85)',
            boxShadow: '0 0 0 0 rgba(47, 116, 235, 0)',
        },
    },
    disabled: {
        backgroundColor: 'rgba(32, 106, 233, 0.45)',
        '&:hover': {
            backgroundColor: 'rgba(32, 106, 233, 0.45)',
        },
    },
    loader: {
        width: '50%',
        height: '44%',
    },
}));
export const WorkCircleProgressBar = ({ hasStarted = false, handleStartStopWork = () => { }, progressBarStyles = {}, disabled = false, smallIcon = false, isLoading = false, ...rest }) => {
    const classes = useStyles({ isDisabled: disabled || isLoading });
    const startProgressbarStyles = {
        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,
        // Colors
        pathColor: theme.palette.primary.main,
        trailColor: theme.palette.grey[300],
    };
    const renderContent = () => {
        if (isLoading) {
            return _jsx(Loading, { color: 'circleBar', hasLabel: false, className: classes.loader }, void 0);
        }
        if (hasStarted) {
            return _jsx(Stop, { className: cx(classes.workIcon, { [classes.smallIcon]: smallIcon }) }, void 0);
        }
        return (_jsx(Start, { className: cx(classes.workIcon, classes.startIcon, {
                [classes.smallIcon]: smallIcon,
                [classes.smallStartIcon]: smallIcon,
            }) }, void 0));
    };
    return (_jsx("div", Object.assign({ className: cx({
            [classes.smallRoot]: smallIcon,
            [classes.normalSizeRoot]: !smallIcon,
        }) }, { children: _jsx(CircularProgressbarWithChildren, Object.assign({}, rest, { strokeWidth: 12, styles: buildStyles({ ...startProgressbarStyles, ...progressBarStyles }) }, { children: _jsx("button", Object.assign({ className: cx(classes.startWorkInnerCircle, {
                    [classes.pulseStart]: hasStarted,
                    [classes.disabled]: disabled,
                }), disabled: disabled, onClick: handleStartStopWork }, { children: renderContent() }), void 0) }), void 0) }), void 0));
};
