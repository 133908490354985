import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Icons } from '../../../index';
import { rangePickerInputActionTypeEnum } from '../../../utils/consts';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > span': {
            cursor: 'pointer',
            color: theme.palette.common.black,
            fontFamily: 'Manrope',
            fontWeight: 700,
            fontSize: 14,
            userSelect: 'none',
            margin: '0 4px',
        },
    },
    arrow: {
        '& > svg': {
            color: theme.palette.grey[500],
        },
    },
    selectedValue: {
        color: `${theme.palette.primary.main} !important`,
    },
}));
const Input = forwardRef(({ value, onChange, setChosenDatePeriod, isOneWeekSelected, isOneMonthSelected, isTwoWeekSelected, isOneDaySelected, chosenDatePeriod, isOneYearSelected, isCustomSelected, ...props }, ref) => {
    const classes = useStyles();
    // TODO: Refactor
    const handleClick = (actionType) => {
        const { from, to } = chosenDatePeriod;
        if (isOneWeekSelected) {
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                const updatedRange = {
                    from: new Date(from.setDate(from.getDate() - 7)),
                    to: new Date(to.setDate(to.getDate() - 7)),
                };
                setChosenDatePeriod?.(updatedRange);
            }
            else {
                const updatedRange = {
                    from: new Date(from.setDate(+from.getDate() + 7)),
                    to: new Date(to.setDate(+to.getDate() + 7)),
                };
                setChosenDatePeriod?.(updatedRange);
            }
        }
        if (isTwoWeekSelected) {
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                const updatedRange = {
                    from: new Date(from.setDate(from.getDate() - 14)),
                    to: new Date(to.setDate(to.getDate() - 14)),
                };
                setChosenDatePeriod?.(updatedRange);
            }
            else {
                const updatedRange = {
                    from: new Date(from.setDate(+from.getDate() + 14)),
                    to: new Date(to.setDate(+to.getDate() + 14)),
                };
                setChosenDatePeriod?.(updatedRange);
            }
        }
        if (isOneMonthSelected) {
            const currentMonth = from.getMonth();
            const currentYear = from.getFullYear();
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                const updatedRange = {
                    from: new Date(currentYear, currentMonth - 1, 1, 0, 0, 0),
                    to: new Date(currentYear, currentMonth, 0, 23, 59, 59),
                };
                setChosenDatePeriod?.(updatedRange);
            }
            else {
                const updatedRange = {
                    from: new Date(currentYear, currentMonth + 1, 1, 0, 0, 0),
                    to: new Date(currentYear, currentMonth + 2, 0, 23, 59, 59),
                };
                setChosenDatePeriod?.(updatedRange);
            }
        }
        if (isOneDaySelected) {
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                const updatedRange = {
                    from: new Date(from.setDate(from.getDate() - 1)),
                    to: new Date(to.setDate(to.getDate() - 1)),
                };
                setChosenDatePeriod?.(updatedRange);
            }
            else {
                const updatedRange = {
                    from: new Date(from.setDate(+from.getDate() + 1)),
                    to: new Date(to.setDate(+to.getDate() + 1)),
                };
                setChosenDatePeriod?.(updatedRange);
            }
        }
        if (isOneYearSelected) {
            const currentYear = from.getFullYear();
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                const updatedRange = {
                    from: new Date(currentYear - 1, 0, 1, 0, 0, 0),
                    to: new Date(currentYear - 1, 11, 31, 23, 59, 59),
                };
                setChosenDatePeriod?.(updatedRange);
            }
            else {
                const updatedRange = {
                    from: new Date(currentYear + 1, 0, 1, 0, 0, 0),
                    to: new Date(currentYear + 1, 11, 31, 23, 59, 59),
                };
                setChosenDatePeriod?.(updatedRange);
            }
        }
        if (isCustomSelected) {
            const difference = Math.floor(moment.duration(moment(to).diff(from)).asDays());
            if (actionType === rangePickerInputActionTypeEnum.PREV) {
                if (difference >= 27) {
                    const updatedRange = {
                        from: moment(from).subtract(1, 'month').toDate(),
                        to: moment(to).date() >= 28 ? moment(to).subtract(1, 'month').endOf('month').toDate() : moment(to).subtract(1, 'month').toDate(),
                    };
                    setChosenDatePeriod?.(updatedRange);
                }
                else {
                    const updatedRange = {
                        from: moment(from).subtract(difference + 1, 'days').toDate(),
                        to: moment(to).subtract(difference + 1, 'days').toDate(),
                    };
                    setChosenDatePeriod?.(updatedRange);
                }
            }
            else if (difference >= 27) {
                const updatedRange = {
                    from: moment(from).add(1, 'month').toDate(),
                    to: moment(to).date() >= 28 ? moment(to).add(1, 'month').endOf('month').toDate() : moment(to).add(1, 'month').toDate(),
                };
                setChosenDatePeriod?.(updatedRange);
            }
            else {
                const updatedRange = {
                    from: moment(from).add(difference + 1, 'days').toDate(),
                    to: moment(to).add(difference + 1, 'days').toDate(),
                };
                setChosenDatePeriod?.(updatedRange);
            }
        }
    };
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [_jsx(Icons.ArrowDropLeft, { className: classes.arrow, onClick: () => handleClick(rangePickerInputActionTypeEnum.PREV) }, void 0), _jsx("span", Object.assign({ "data-testid": 'rangePicker-input', ref: ref }, props, { className: classes.selectedValue }, { children: value }), void 0), _jsx(Icons.ArrowDropRight, { className: classes.arrow, onClick: () => handleClick(rangePickerInputActionTypeEnum.NEXT) }, void 0)] }), void 0));
});
export default Input;
