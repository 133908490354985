import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import GrayEye from '../icons/GrayEye';
import Input from './Input';
import useStyles from './styles/Input.styles';
const Password = ({ disabled, ...props }) => {
    const styles = useStyles();
    const [visible, setVisible] = useState(false);
    return (_jsx(Input, Object.assign({}, props, { type: visible ? 'text' : 'password', passwordField: true, testId: 'ui-lib-input-password', disabled: disabled, endAdornment: (_jsx(GrayEye, { className: styles.eyeIcon, "data-testid": 'input-password-eye-icon', onClick: () => !disabled && setVisible(!visible) }, void 0)) }), void 0));
};
export default Password;
