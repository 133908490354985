import { makeStyles } from '@material-ui/core';
const alertColor = '#F26A02';
const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        overflow: 'hidden',
        minHeight: 48,
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        userSelect: 'none',
        cursor: 'pointer',
        border: '2px solid transparent',
        transition: theme.transitions.create('border'),
        '&:hover': {
            border: `2px solid ${alertColor}`,
        },
    },
    disabled: {
        cursor: 'not-allowed',
        '&:hover': {
            border: '2px solid transparent !important',
        },
    },
    topContainer: {
        padding: '2px',
        height: 24,
        background: '#FFEED6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    iconAndDurationContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    bottomContainer: {
        height: 24,
        padding: '0px 8px',
        background: '#FCDEB0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleBlock: {
        color: alertColor,
        fontWeight: 700,
        width: '80%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    typeBlock: {
        fontWeight: 500,
        color: alertColor,
    },
    breakIcon: {
        color: alertColor,
        '& svg': {
            width: '20px',
            height: '20px',
        },
    },
    durationBlock: {
        fontWeight: 500,
    },
    timeRange: {
        color: theme.palette.grey[700],
    },
}));
export default useStyles;
