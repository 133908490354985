import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { Tabs as MuiTabs, Tab as MuiTab, makeStyles, } from '@material-ui/core';
import cx from 'classnames';
const useStyles = makeStyles((theme) => ({
    tabsStyle: {
        position: 'relative',
        '&::after': {
            position: 'absolute',
            display: 'block',
            content: '""',
            bottom: 0,
            left: 0,
            height: 2,
            width: '100%',
            backgroundColor: theme.palette.grey[300],
        },
        '& > button': {
            color: theme.palette.common.black,
            marginRight: 32,
        },
    },
    tabStyle: {
        fontWeight: 500,
        fontSize: 14,
        textTransform: 'none',
        minWidth: 'initial',
        padding: 0,
        opacity: 1,
    },
    tabSelected: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 700,
    },
    tabIndicator: {
        background: theme.palette.primary.main,
        borderRadius: '8px 8px 0 0',
    },
    divider: {
        position: 'absolute',
        backgroundColor: theme.palette.grey[300],
        width: '100%',
        height: 2,
        bottom: 0,
    },
    tabInRight: {
        marginLeft: 'auto',
    },
    lastTabInRight: {
        marginRight: '0 !important',
    },
}));
const TabPanel = ({ children, value, index, }) => {
    if (value === index) {
        return _jsx(_Fragment, { children: children }, void 0);
    }
    return _jsx(_Fragment, {}, void 0);
};
export const Tabs = ({ tabTitles = ['Item 1', 'Item 2'], contents = [_jsx(_Fragment, { children: "Content 1" }, void 0), _jsx(_Fragment, { children: "Content 2" }, void 0)], selectedTabIndex = 0, disabledTabs = [], tabToStartPositionRight = '', ...props }) => {
    const styles = useStyles();
    const [value, setValue] = useState(selectedTabIndex);
    useEffect(() => {
        setValue(selectedTabIndex);
    }, [selectedTabIndex]);
    return (_jsxs(_Fragment, { children: [_jsx(MuiTabs, Object.assign({}, props, { value: value, onChange: (_, val) => {
                    props.onChange && props?.onChange(val);
                    setValue(val);
                }, classes: {
                    indicator: styles.tabIndicator,
                    flexContainer: styles.tabsStyle,
                } }, { children: tabTitles.map((label, i) => (_jsx(MuiTab, { disabled: disabledTabs.includes(label.toString()), classes: {
                        root: styles.tabStyle,
                        selected: styles.tabSelected,
                    }, disableRipple: true, label: label, className: cx({
                        [styles.tabInRight]: tabToStartPositionRight === label,
                        [styles.lastTabInRight]: !!tabToStartPositionRight && i === tabTitles.length - 1,
                    }) }, i))) }), void 0), contents.map((content, i) => (_jsx(TabPanel, Object.assign({ value: value, index: i }, { children: content }), i)))] }, void 0));
};
