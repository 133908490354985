import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Body, Caption } from '../Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    senderRoot: {
        flexDirection: 'row-reverse',
    },
    container: {
        padding: '8px 12px',
        maxWidth: 440,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        backgroundColor: '#ECF6FF',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
    },
    sender: {
        borderRadius: '16px 16px 0px 16px',
    },
    others: {
        borderRadius: '16px 16px 16px 0px',
    },
    username: {
        color: theme.palette.primary.main,
        fontSize: '13px',
        lineHeight: '20px',
        fontFamily: theme.typography.fontFamily,
    },
    message: {
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '20px',
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.grey[700],
    },
    line: {
        width: 4,
        height: '100%',
        backgroundColor: '#7CA6F4E5',
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
}));
const ReplyBubble = ({ children, style, className, type, userName, ...rest }) => {
    const classes = useStyles();
    return (_jsx("div", Object.assign({ className: cx(className, classes.root, {
            [classes.senderRoot]: type === 'sender',
        }), style: style }, rest, { children: _jsxs("div", Object.assign({ className: cx(classes.container, {
                [classes.sender]: type === 'sender',
                [classes.others]: type === 'others',
            }) }, { children: [_jsx("div", { className: classes.line }, void 0), _jsx(Caption, Object.assign({ weight: 700, className: classes.username }, { children: userName }), void 0), _jsx(Body, Object.assign({ variant: 'small', className: classes.message }, { children: children }), void 0)] }), void 0) }), void 0));
};
export default ReplyBubble;
