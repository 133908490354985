import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from 'classnames';
import { Skeleton } from '../../../Skeleton';
import { useStyles } from './EmptyEntry.styles';
import { useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems } from '../../MenuOptions';
import { emptyCellMenuItems } from '../../MenuOptions/options';
import { PasteIcon, Plus } from '../../../icons';
export const EmptyEntry = ({ testId, disabled = false, loading, isOpenShift = false, isFlashMode = false, isCopyMode = false, onClick, menuItems, children, ...props }) => {
    const classes = useStyles();
    const { handleClose, anchorEl, handleClick } = useDropdownMenu();
    const containerClasses = cx(classes.container, {
        [classes.disabled]: disabled,
        [classes.isFlashMode]: isFlashMode || isCopyMode,
    });
    if (loading) {
        return _jsx(Skeleton, { animation: false, variant: 'rect', className: classes.skeletonBlock }, void 0);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const combineMenuItems = () => menuItems?.map((menuItem) => ({
        ...emptyCellMenuItems.find(({ key }) => key === menuItem.key), ...menuItem,
    })) || [];
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'empty-entry', className: containerClasses, "data-testid": testId }, props, { children: [children, !disabled && (_jsx("button", Object.assign({ "aria-label": 'add-button', onClick: (e) => {
                            // Important: e.detail === 0 prevents any click propagation with keyboard keys.
                            // Prevents accidental insertion of shifts/absences when typing enter when delete dialog is open
                            if (e.ctrlKey || e.metaKey || e.detail === 0) {
                                // Do not open the add menu when CTRL or CMD key is pressed
                                return;
                            }
                            const clickHandler = isFlashMode || isCopyMode ? onClick : handleClick;
                            if (clickHandler) {
                                clickHandler(e);
                            }
                        }, className: classes.hoverBlock }, { children: isCopyMode ? _jsx(PasteIcon, { "aria-label": 'paste-icon' }, void 0) : _jsx(Plus, { "aria-label": 'plus-icon' }, void 0) }), void 0))] }), void 0), _jsx(EntryMenuItems, { handleClose: handleClose, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
};
