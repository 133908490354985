import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles';
const Briefcase = (props) => {
    const { className, ...rest } = props;
    return (_jsx("span", Object.assign({ className: cx(useStyles().jobDoneIcon, {
            [String(className)]: className,
        }) }, rest, { children: _jsxs("svg", Object.assign({ width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M15.0965 2H8.90418C5.09723 2 2 5.09685 2 8.90346V15.0965C2 18.9031 5.09723 22 8.90418 22H15.0965C18.9031 22 22 18.9031 22 15.0965V8.90346C22 5.0969 18.9031 2 15.0965 2Z', fill: 'currentColor' }, void 0), _jsx("path", { d: 'M13.5 13.875C13.5 14.0822 13.3322 14.25 13.125 14.25H10.875C10.6678 14.25 10.5 14.0822 10.5 13.875V12.75H6V16.125C6 16.725 6.525 17.25 7.125 17.25H16.875C17.475 17.25 18 16.725 18 16.125V12.75H13.5V13.875ZM16.875 9H15V7.875C15 7.275 14.475 6.75 13.875 6.75H10.125C9.525 6.75 9 7.275 9 7.875V9H7.125C6.525 9 6 9.525 6 10.125V12H18V10.125C18 9.525 17.475 9 16.875 9ZM13.5 9H10.5V8.25H13.5V9Z', fill: 'white' }, void 0)] }), void 0) }), void 0));
};
export default Briefcase;
