import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cx from 'classnames';
import { Switch as MUISwitch, FormControlLabel } from '@material-ui/core';
import useStyles from './styles/Switch.styles';
export const Switch = forwardRef(({ size, className, label, labelPlacement, labelClassName, doubleLabel, ...props }, ref) => {
    const classes = useStyles({ size, labelPlacement, doubleLabel });
    return (_jsx(FormControlLabel, { classes: {
            root: cx(classes.muiFormLabel, className),
            label: cx(classes.label, labelClassName),
        }, label: label, labelPlacement: labelPlacement, control: (_jsx(MUISwitch, Object.assign({ inputRef: ref, focusVisibleClassName: classes.focusVisible, disableRipple: true, classes: {
                root: classes.root,
                switchBase: classes.switchBase,
                sizeSmall: classes.smallSize,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
                disabled: classes.disabled,
            } }, props), void 0)) }, void 0));
});
