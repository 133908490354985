import { jsx as _jsx } from "react/jsx-runtime";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import cx from 'classnames';
import { Icons } from '../..';
import useStyles from './styles/Breadcrumb.styles';
export const Breadcrumb = ({ elements = [], onClick = () => { }, }) => {
    const classes = useStyles();
    return (_jsx(Breadcrumbs, Object.assign({ separator: _jsx(Icons.ShevronRight, {}, void 0) }, { children: elements.map((element, i) => (_jsx("button", Object.assign({ onClick: () => onClick(element.value), 
            // disabled={i === elements.length - 1}
            className: cx(classes.element, {
                [classes.elementActive]: i === elements.length - 1,
                [classes.firstElement]: i === 0,
            }) }, { children: element.label }), element.value))) }), void 0));
};
