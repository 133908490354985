import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { useEffect, } from 'react';
import { Button, Checkbox, Icons, Radio, } from '../..';
import { ChoiceTag } from '../ChoiceTag';
import useStyles from './styles/Filter.styles';
export const Filter = ({ locale, getSelectedFilters, state, setState, }) => {
    const classes = useStyles();
    const selectedFilterIndex = state.findIndex(({ selected }) => selected);
    const selectedFilter = state[selectedFilterIndex];
    useEffect(() => {
        if (getSelectedFilters) {
            const selectedFilterTypeAndOption = [];
            state.forEach(({ options, value }) => {
                const selectedFilterOptions = options.filter(({ selected }) => selected);
                if (selectedFilterOptions.length) {
                    selectedFilterTypeAndOption.push({ value, options: [...selectedFilterOptions.map((filterOption) => filterOption.value)] });
                }
            });
            getSelectedFilters(selectedFilterTypeAndOption);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);
    const handleOptionClick = (type, filterIndex, optionIndex) => {
        setState((prev) => {
            const _prev = [...prev];
            _prev[filterIndex] = {
                ..._prev[filterIndex],
                options: _prev[filterIndex].options.map(({ selected, ...rest }, i) => ({
                    ...rest,
                    // eslint-disable-next-line no-nested-ternary
                    selected: i === optionIndex
                        ? (_prev[filterIndex].options[optionIndex].selected && type === 'radio') || !_prev[filterIndex].options[optionIndex].selected
                        : type === 'radio'
                            ? false
                            : _prev[filterIndex].options[i].selected,
                })),
            };
            return _prev;
        });
    };
    const handleFilterClick = (filterIndex) => {
        setState((prev) => prev.map((rest, i) => ({
            ...rest,
            selected: i === filterIndex ? !prev[filterIndex].selected : false,
        })));
    };
    const handleResetFilters = () => {
        setState(state.map(({ type, options, ...rest }, filterIndex) => ({
            ...rest,
            type,
            selected: filterIndex === 0,
            options: options.map((option, optionIndex) => ({
                ...option,
                selected: type === 'radio' ? optionIndex === 0 : true,
            })),
        })));
    };
    return (_jsxs("div", Object.assign({ className: classes.root }, { children: [_jsx("div", Object.assign({ className: classes.flexContainer }, { children: state.map(({ label, value, options, type, selected, }, filterIndex) => {
                    const selectedOptionCount = options.filter((option) => option.selected).length;
                    return (_jsxs("div", Object.assign({ className: classes.flexContainer }, { children: [_jsx(ChoiceTag, Object.assign({ selected: selected, onClick: () => handleFilterClick(filterIndex) }, { children: _jsxs("div", Object.assign({ className: classes.filterLabel }, { children: [_jsx("span", Object.assign({ className: cx({
                                                [classes.selected]: !!selectedOptionCount && !selected,
                                                [classes.active]: selected,
                                            }) }, { children: label }), void 0), _jsxs("div", Object.assign({ className: classes.iconAndCountContainer }, { children: [type === 'checkbox' && (_jsx(_Fragment, { children: _jsx("div", Object.assign({ className: cx({
                                                            [classes.selectedOptionCount]: !!selectedOptionCount,
                                                        }) }, { children: selectedOptionCount !== 0 && selectedOptionCount }), void 0) }, void 0)), _jsx(Icons.ArrowDropDown, { className: cx(classes.filterIcon, {
                                                        [classes.selected]: !!selectedOptionCount && !selected,
                                                        [classes.active]: selected,
                                                    }) }, void 0)] }), void 0)] }), void 0) }), void 0), filterIndex === state.length - 1 && (_jsx(Button, { color: 'ghost', label: locale.resetFilter, variant: 'autoWidth', onClick: handleResetFilters }, void 0))] }), value));
                }) }), void 0), _jsx("div", Object.assign({ className: classes.flexContainer }, { children: selectedFilter && selectedFilter?.options.map((option, optionIndex) => (selectedFilter.type === 'checkbox'
                    ? (_jsx(Checkbox, { onClick: () => handleOptionClick(selectedFilter.type, selectedFilterIndex, optionIndex), parentContainerClassname: cx(classes.option, classes.customCheckbox), checked: option.selected, label: option.label }, option.value))
                    : (_jsx(Radio, { onClick: () => handleOptionClick(selectedFilter.type, selectedFilterIndex, optionIndex), parentContainerClassname: cx(classes.option, classes.customRadio), checked: option.selected, label: option.label }, option.value)))) }), void 0)] }), void 0));
};
