import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/ChoiceTag.styles';
import { SIZES } from './utils';
export function ChoiceTag({ children, style, className, id = 'choiceTag', size = SIZES.Medium, selected = false, disabled = false, ...rest }) {
    const styles = useStyles();
    const classes = cx(styles.root, styles[size], {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
        [`${String(className)}`]: !!className,
    });
    return (_jsx("div", Object.assign({ style: style, id: id, className: classes }, rest, { children: children }), void 0));
}
