import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { absenceIconEnum, absenceColorCodeEnum } from '@jobdone/jobdone-shared-library';
import useStyles from './AbsenceEntry.style';
import { Caption, Text } from '../../../Typography';
import { EditAlt, MoreHorizontal, Absence, AbsenceOther, Accident, Vacation, SickDay, Education, Military, Funeral, Maternity, Trash, ArrowUpRight, Violated, AwaitingApprovalCircle, ApprovedCircleCheck, } from '../../../icons';
import { AbsenceEntryTypes } from '../../commons/enums';
import { getAbbreviatedName, useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems, absenceMenuItems } from '../..';
import { Tooltip } from '../../../Tooltip';
import { ConfirmationStatusEnum, rangePickerVariantEnum } from '../../../../utils';
import { Avatar } from '../../../Avatar';
export const AbsenceEntry = ({ testId, entryType = 'basic', isPublished = false, title = 'Absence 1', time = 'All Day', color = absenceColorCodeEnum.RED, className, blockedTitle, icon = absenceIconEnum.ABSENCE, onEditClick, onDeleteClick, menuItems, hasFlash, showHoverActions = true, selected, isFlashMode = false, labels = {
    deleteLabel: 'Delete',
}, view = rangePickerVariantEnum['1_WEEK'], isCompact = false, assignedUserAvatar, assignedUserFullName, isResourceView, isDifferentFromTemplate = false, isConfirmationMode, confirmationStatus, ...rest }) => {
    const classes = useStyles({
        color, hasFlash, selected, isFlashMode, isBlocked: entryType === AbsenceEntryTypes.isBlocked,
    });
    const { handleClose: handleCloseMore, anchorEl, handleClick: handleClickMore } = useDropdownMenu();
    const [absenceFromTime, absenceToTime] = time.split(' - ');
    const absenceFromTimeRawHour = absenceFromTime.split(':')[0];
    const absenceFromTimeHour = absenceFromTimeRawHour[0] === '0' ? absenceFromTimeRawHour[1] : absenceFromTimeRawHour;
    const absenceFromTimeMinute = absenceFromTime?.split(':')[1];
    const absenceToTimeRawHour = absenceToTime?.split(':')?.[0];
    const absenceToTimeHour = absenceToTimeRawHour?.[0] === '0' ? absenceToTimeRawHour[1] : absenceToTimeRawHour;
    const absenceToTimeMinute = absenceToTime?.split(':')[1];
    const combineMenuItems = () => menuItems?.map((menuItem) => ({
        ...absenceMenuItems.find(({ key }) => key === menuItem.key), ...menuItem,
    })) || [];
    const cantRenderOverlay = () => (entryType === AbsenceEntryTypes.isCopied || !showHoverActions);
    const renderConfirmationIcon = () => {
        if (isConfirmationMode && confirmationStatus) {
            switch (confirmationStatus) {
                case ConfirmationStatusEnum.rejected:
                    return (_jsx("div", Object.assign({ className: cx(classes.confirmationModeBlock, classes.confirmationRejected) }, { children: _jsx(Violated, {}, void 0) }), void 0));
                case ConfirmationStatusEnum.open:
                    return (_jsx("div", Object.assign({ className: classes.confirmationModeBlock }, { children: _jsx(AwaitingApprovalCircle, {}, void 0) }), void 0));
                case ConfirmationStatusEnum.approved:
                    return (_jsx("div", Object.assign({ className: classes.confirmationModeBlock }, { children: _jsx(ApprovedCircleCheck, {}, void 0) }), void 0));
                default:
                    return _jsx(_Fragment, {}, void 0);
            }
        }
        return _jsx(_Fragment, {}, void 0);
    };
    const renderOneWeekOverlay = () => {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneWeekOverlay, {
                        [classes.compactViewOverlayButton]: isCompact,
                    }), onClick: onDeleteClick }, { children: [_jsx(Trash, { className: classes.trashIcon }, void 0), _jsx(Text, Object.assign({ className: classes.deleteLabel }, { children: labels.deleteLabel }), void 0)] }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneWeekOverlay, {
                        [classes.compactViewOverlayButton]: isCompact,
                    }) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: classes.overlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", Object.assign({ onClick: handleClickMore, className: classes.overlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    const renderTwoWeeksOverlay = () => {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.twoWeeksOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.twoWeeksOverlay) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", Object.assign({ onClick: handleClickMore, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    const renderOneMonthOverlay = () => {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneMonthOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneMonthOverlay) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: classes.oneMonthOverlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", Object.assign({ onClick: handleClickMore, className: classes.oneMonthOverlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    const renderAssignedUser = () => (_jsx(Avatar, { src: assignedUserAvatar, placeHolder: assignedUserFullName, size: 'xs', className: cx(classes.absenceIcon, classes.assignedUserAvatarBlock) }, void 0));
    const renderAbsenceIcon = () => {
        if (isResourceView) {
            return renderAssignedUser();
        }
        switch (icon) {
            case absenceIconEnum.ABSENCE:
                return _jsx(Absence, { "aria-label": 'absence-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.ABSENCE_OTHER:
                return _jsx(AbsenceOther, { "aria-label": 'absence-other-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.ACCIDENT:
                return _jsx(Accident, { "aria-label": 'accident-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.VACATION:
                return _jsx(Vacation, { "aria-label": 'vacation-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.SICK_DAY:
                return _jsx(SickDay, { "aria-label": 'sick-day-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.EDUCATION:
                return _jsx(Education, { "aria-label": 'education-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.MILITARY:
                return _jsx(Military, { "aria-label": 'military-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.FUNERAL:
                return _jsx(Funeral, { "aria-label": 'funeral-icon', className: classes.absenceIcon }, void 0);
            case absenceIconEnum.MATERNITY:
                return _jsx(Maternity, { "aria-label": 'maternity-icon', className: classes.absenceIcon }, void 0);
            default:
                return _jsx(Absence, { "aria-label": 'absence-icon', className: classes.absenceIcon }, void 0);
        }
    };
    const renderIsDifferentFromTemplate = isDifferentFromTemplate && (_jsx("div", { className: classes.differentFromTemplate }, void 0));
    const renderOneWeekContent = () => {
        const renderContent = () => {
            if (isCompact) {
                return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsxs("div", Object.assign({ className: classes.compactViewIconAndTime }, { children: [entryType === AbsenceEntryTypes.isBlocked
                                        ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.compactViewBlockedIcon }, void 0)
                                        : renderAbsenceIcon(), _jsx("div", Object.assign({ className: classes.compactTimeContainer }, { children: !absenceToTime
                                            ? (_jsx(Caption, Object.assign({ variant: 'regular', className: classes.compactViewAbsenceName }, { children: getAbbreviatedName(absenceFromTime) }), void 0))
                                            : (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: absenceFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: absenceFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: absenceToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: absenceToTimeMinute }), void 0)] }), void 0)] }, void 0)) }), void 0)] }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.absenceNameBlock, classes.compactViewAbsenceName, {
                                    [classes.marginRight]: isDifferentFromTemplate,
                                }), weight: 500 }, { children: title }), void 0)] }), void 0) }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer) }, { children: [renderAbsenceIcon(), renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsx(Caption, Object.assign({ variant: 'regular' }, { children: time }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneWeekBottomContainer) }, { children: [_jsx(Caption, Object.assign({ className: classes.absenceNameBlock, variant: 'regular' }, { children: title }), void 0), entryType === AbsenceEntryTypes.isBlocked && _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.blockedIcon }, void 0)] }), void 0)] }, void 0));
        };
        return (_jsx("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.oneWeekContainer, {
                [classes.isHover]: !cantRenderOverlay() && showHoverActions,
                [classes.isBlocked]: entryType === AbsenceEntryTypes.isBlocked,
                [`${String(className)}`]: !!className,
                [classes.flash]: hasFlash,
                [classes.selected]: selected,
                [classes.isFlashMode]: isFlashMode,
            }) }, { children: selected
                ? (_jsxs("div", Object.assign({ className: classes.selectedContainer }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderContent(), renderOverlay()] }), void 0))
                : (_jsxs(_Fragment, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderContent(), renderOverlay()] }, void 0)) }), void 0));
    };
    const renderTwoWeeksContent = () => (_jsxs("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.twoWeeksContainer, {
            [classes.isHover]: !cantRenderOverlay() && showHoverActions,
            [classes.isBlocked]: entryType === AbsenceEntryTypes.isBlocked,
            [`${String(className)}`]: !!className,
            [classes.flash]: hasFlash,
            [classes.selected]: selected,
            [classes.isFlashMode]: isFlashMode,
        }) }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.twoWeeksTopContainer) }, { children: [entryType === AbsenceEntryTypes.isBlocked
                        ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.absenceIcon }, void 0)
                        : renderAbsenceIcon(), _jsx(Caption, Object.assign({ className: cx(classes.absenceNameBlock, classes.twoWeekViewAbsenceNameBlock, {
                            [classes.marginRight]: isDifferentFromTemplate,
                        }), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.twoWeeksBottomContainer) }, { children: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: absenceFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: absenceFromTimeMinute }), void 0)] }), void 0), absenceToTimeMinute && (_jsxs(_Fragment, { children: ["-", _jsxs("div", Object.assign({ className: classes.twoWeeksTimeContainer }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: absenceToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.twoWeeksTimeMinute, size: 8, variant: 'regular' }, { children: absenceToTimeMinute }), void 0)] }), void 0)] }, void 0))] }, void 0) }), void 0)] }), void 0));
    const renderOneMonthContent = () => {
        const renderContent = () => {
            if (isCompact) {
                return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: [renderConfirmationIcon(), _jsx("div", Object.assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsx("div", Object.assign({ className: classes.compactTimeEntry }, { children: !absenceFromTimeMinute
                                            ? (_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: getAbbreviatedName(absenceFromTimeHour) }), void 0))
                                            : (_jsxs(_Fragment, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: absenceFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: absenceFromTimeMinute }), void 0)] }, void 0)) }), void 0) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: _jsx(Caption, Object.assign({ className: cx(classes.absenceNameBlock, classes.oneMonthAbsenceNameBlock), variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0), entryType === AbsenceEntryTypes.isBlocked && (_jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, {
                            [classes.paddingRight]: isDifferentFromTemplate,
                        }) }, { children: [renderConfirmationIcon(), entryType === AbsenceEntryTypes.isBlocked
                                ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.absenceIcon }, void 0)
                                : renderAbsenceIcon()] }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer) }, { children: _jsx(Caption, Object.assign({ className: classes.absenceNameBlock, variant: 'regular' }, { children: getAbbreviatedName(title) }), void 0) }), void 0)] }, void 0));
        };
        return (_jsxs("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.oneMonthContainer, {
                [classes.isHover]: !cantRenderOverlay() && showHoverActions,
                [classes.isBlocked]: entryType === AbsenceEntryTypes.isBlocked,
                [`${String(className)}`]: !!className,
                [classes.flash]: hasFlash,
                [classes.selected]: selected,
                [classes.isFlashMode]: isFlashMode,
            }) }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderIsDifferentFromTemplate, renderOverlay(), renderContent()] }), void 0));
    };
    const renderOverlay = () => {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekOverlay();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksOverlay();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthOverlay();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    const renderContent = () => {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekContent();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksContent();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthContent();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    if (entryType === AbsenceEntryTypes.isBlocked && blockedTitle) {
        return (_jsx(Tooltip, Object.assign({ title: blockedTitle }, { children: renderContent() }), void 0));
    }
    return renderContent();
};
