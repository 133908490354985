import React from 'react';
import { az, de, it, fr, enGB, ru, } from 'date-fns/locale';
import moment from 'moment';
export const useCombinedRefs = (innerRef, forwardRef) => {
    const targetRef = React.useRef(null);
    React.useEffect(() => {
        const refs = [innerRef, forwardRef];
        refs.forEach((ref) => {
            const currentRef = ref;
            if (!currentRef)
                return;
            if (typeof currentRef === 'function') {
                currentRef(targetRef.current);
            }
            else {
                // Type assertion to MutableRefObject to allow assignment
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [forwardRef, innerRef]);
    return targetRef;
};
export const getYear = (date) => date.getFullYear();
export const getMonth = (date) => date.getMonth();
export const getDay = (date) => date.getDate();
export const getMondayOfWeek = (date) => new Date(date.setDate(date.getDate() - (date.getDay() + 6) % 7));
export const getSundayOfWeek = (date) => new Date(date.setDate(date.getDate() - (date.getDay() - 7) % 7));
export const getSundayOfNextWeek = (date) => new Date(date.setDate(date.getDate() - (date.getDay() - 14) % 14));
export const getEndOfMonth = (date) => new Date(moment(date).endOf('month').toString());
export const getStartOfMonth = (date) => new Date(moment(date).startOf('month').toString());
export const getEndOfDay = (date) => new Date(moment(date).endOf('day').toString());
export const getStartOfDay = (date) => new Date(moment(date).startOf('day').toString());
export const getStartOfYear = (date) => new Date(moment(date).startOf('year').toString());
export const getEndOfYear = (date) => new Date(moment(date).endOf('year').toString());
export const getMaxDate = (date) => new Date(moment(date).add(31, 'days').toString());
export const getAbbreviatedName = (name) => name.split(' ').map((n) => n[0]).join('');
export var JobDoneLocales;
(function (JobDoneLocales) {
    JobDoneLocales["GERMAN"] = "de-DE";
    JobDoneLocales["SWISS_GERMAN"] = "de-CH";
    JobDoneLocales["GREAT_BRITAIN_ENGLISH"] = "en-GB";
    JobDoneLocales["ENGLISH"] = "en-US";
    JobDoneLocales["AZERBAIJANI"] = "az";
    JobDoneLocales["ITALIAN"] = "it-IT";
    JobDoneLocales["FRENCH"] = "fr-FR";
    JobDoneLocales["RUSSIAN"] = "ru";
})(JobDoneLocales || (JobDoneLocales = {}));
export const getDateFnsLocaleFromJobDoneLocale = (jobdoneLocale) => {
    switch (jobdoneLocale) {
        case JobDoneLocales.GERMAN:
            return de;
        case JobDoneLocales.SWISS_GERMAN:
            return de;
        case JobDoneLocales.AZERBAIJANI:
            return az;
        case JobDoneLocales.ITALIAN:
            return it;
        case JobDoneLocales.FRENCH:
            return fr;
        case JobDoneLocales.ENGLISH:
            return enGB;
        case JobDoneLocales.RUSSIAN:
            return ru;
        default:
            return enGB; // dateDns requires another format for en --> we use enGB
    }
};
export const useDropdownMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return { handleClick, handleClose, anchorEl };
};
export const getMonthNames = (locale) => {
    const monthArray = [];
    const objDate = new Date();
    objDate.setDate(1);
    for (let i = 0; i < 12; i += 1) {
        objDate.setMonth(i);
        const month = objDate.toLocaleString(locale, { month: 'long' });
        monthArray.push(month);
    }
    return monthArray;
};
export const createArrayWithKeysAsValue = (count) => Array.from(Array(count).keys());
