import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useRef, useEffect, useState, } from 'react';
import cx from 'classnames';
import { InputBase } from '@material-ui/core';
import useStyles from './styles/Input.styles';
import ErrorWithExclamation from '../icons/ErrorWithExclamation';
import GreenCheck from '../icons/GreenCheck';
import { useCombinedRefs } from '../../utils/helpers';
import '@emotion/core';
import Line from './Line';
import { Skeleton } from '../Skeleton';
import { FormControl } from '../FormControl/FormControl';
const Input = forwardRef(({ passwordField = false, testId = '', id = 'test', className = '', labelClassName = '', locale = {
    errorMessage: '',
    label: 'Label',
    note: '',
    placeholder: 'Placeholder',
}, containerClassName = '', error = false, success = false, endAdornment = '', hiddenLabel, multiline = false, css = {}, required, loading = false, onFocus, onBlur, ...props }, ref) => {
    const innerRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const combinedRef = useCombinedRefs(innerRef, ref);
    const styles = useStyles();
    const inputClassNames = cx(styles.input, className, {
        [styles.inputError]: error,
        [styles.inputSuccess]: success,
    });
    const lineClassNames = cx(styles.line, {
        [styles.lineError]: error,
        [styles.lineSuccess]: success,
    });
    const containerClassNames = cx(styles.container, containerClassName);
    const renderEndAdornment = () => {
        if (passwordField && endAdornment && error) {
            return (_jsxs("div", Object.assign({ className: styles.errorTypePassowrdContainer }, { children: [endAdornment, _jsx(ErrorWithExclamation, { "data-testid": 'input-password-error-exclamation-icon', className: styles.errorIcon }, void 0)] }), void 0));
        }
        if (endAdornment) {
            return (_jsx("button", Object.assign({ className: styles.buttonAsDiv, onClick: (e) => {
                    e.preventDefault();
                    setIsActive(true);
                } }, { children: endAdornment }), void 0));
        }
        if (error)
            return _jsx(ErrorWithExclamation, { "data-testid": 'input-error-exclamation-icon', style: { marginRight: 14 } }, void 0);
        if (success)
            return _jsx(GreenCheck, { "data-testid": 'input-success-check-icon', style: { marginRight: 14 } }, void 0);
        return '';
    };
    const handleFocus = (e) => {
        onFocus?.(e);
        setIsActive(true);
    };
    const handeBlur = (e) => {
        onBlur?.(e);
        setIsActive(false);
    };
    useEffect(() => {
        const node = combinedRef.current;
        if (node) {
            const container = node.parentElement;
            const inputContainer = container?.children[1];
            const input = inputContainer?.firstChild;
            if (input) {
                if (input.disabled) {
                    inputContainer?.classList.add(styles.containerOnInputDisabled);
                }
                else {
                    inputContainer?.classList.remove(styles.containerOnInputDisabled);
                }
            }
        }
    }, [ref, combinedRef, innerRef, styles.containerOnInputDisabled]);
    if (loading) {
        return (_jsxs("div", Object.assign({ className: containerClassNames }, { children: [_jsx(FormControl, { hiddenLabel: hiddenLabel, id: `${id}-${String(locale?.label)}`, required: required, label: locale?.label }, void 0), _jsx(Skeleton, { className: styles.skeletonInputContainer, variant: 'rect' }, void 0)] }), void 0));
    }
    return (_jsx("div", Object.assign({ className: containerClassNames }, { children: _jsxs(FormControl, Object.assign({ active: isActive, hiddenLabel: hiddenLabel, error: error, errorMessage: locale.errorMessage, success: success, id: `${id}-${String(locale?.label)}`, required: required, label: locale?.label }, { children: [locale.note && (_jsx("span", { className: styles.note, 
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML: { __html: locale.note } }, void 0)), _jsx(InputBase, Object.assign({}, props, { css: {}, ref: combinedRef, className: inputClassNames, id: id, onFocus: handleFocus, onBlur: handeBlur, "data-testid": testId, placeholder: locale?.placeholder, error: error, endAdornment: !multiline && renderEndAdornment(), multiline: multiline }), void 0), multiline && _jsx(Line, { className: lineClassNames }, void 0)] }), void 0) }), void 0));
});
export default Input;
