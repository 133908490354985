import { jsx as _jsx } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/Loading.styles';
export function Loading({ color, hasLabel, className }) {
    const styles = useStyles();
    const classes = cx(className, styles.loader, styles[color], {
        [styles.marginRight]: hasLabel,
    });
    return (_jsx("div", { "data-testid": 'button-loader', className: classes }, void 0));
}
