import { jsx as _jsx } from "react/jsx-runtime";
import { Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles(() => ({
    default: {
        backgroundColor: '#16243D',
        color: '#fff',
        padding: '6px 8px',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '16px',
        borderRadius: '8px',
        margin: '8px',
    },
    custom: {
        backgroundColor: 'transparent',
    },
}));
export const Tooltip = ({ children, className, title, isCustom, ...rest }) => {
    const styles = useStyles();
    const classes = isCustom ? styles.custom : styles.default;
    return (_jsx(MUITooltip, Object.assign({ title: title, className: className, enterDelay: 0, classes: {
            tooltip: classes,
        } }, rest, { children: children }), void 0));
};
