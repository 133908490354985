import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem, MenuList, Popover, } from '@material-ui/core';
import cx from 'classnames';
import useStyles from './styles/DropdownMenu.styles';
export function DropdownMenu({ anchorEl, open, handleClose, className, paperClassName, children, anchorOrigin = { horizontal: 'right', vertical: 'bottom' }, ...props }) {
    const styles = useStyles();
    const classes = cx({
        [`${String(className)}`]: !!className,
    });
    const paperClasses = cx(styles.paper, {
        [`${String(paperClassName)}`]: !!paperClassName,
    });
    return (_jsx(Popover, Object.assign({ disableScrollLock: true, anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, onClick: handleClose, transformOrigin: { horizontal: 'right', vertical: 'top' }, anchorOrigin: anchorOrigin, classes: {
            root: classes,
            paper: paperClasses,
        } }, props, { children: _jsx(MenuList, { children: children }, void 0) }), void 0));
}
DropdownMenu.Item = function Item({ children, ...props }) {
    const styles = useStyles();
    return (_jsx(MenuItem, Object.assign({}, props, { classes: { root: styles.listItem } }, { children: children }), void 0));
};
