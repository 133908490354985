import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Select, { components, } from 'react-select';
import cx from 'classnames';
import { Icons } from '../..';
import { useStyles, selectStyles } from './styles/Dropdown.styles';
import { FormControl } from '../FormControl/FormControl';
import { Avatar } from '../Avatar';
const Dropdown = ({ className = '', hiddenLabel, locale = { label: 'Label', errorMessage: 'Error', placeholder: 'Select...' }, labelClassName = '', labelId = 'select-label', error = false, testId = 'dropdown', itemPerPageSelect = false, required, containerClassName = '', withAvatar, hideCheckIcon, isSearchable = false, isMulti = false, ...rest }) => {
    const styles = useStyles({ error, itemPerPageSelect, isMulti });
    const dropdownClassNames = cx(styles.select, className);
    const [isActive, setIsActive] = useState(false);
    const handleFocus = () => {
        setIsActive(true);
    };
    const handeBlur = () => {
        setIsActive(false);
    };
    return (_jsx("div", Object.assign({ className: containerClassName }, { children: _jsx(FormControl, Object.assign({ active: isActive, hiddenLabel: hiddenLabel, error: error, errorMessage: locale.errorMessage, id: `${labelId}-${String(locale?.label)}`, required: required, label: locale?.label }, { children: _jsx(Select, Object.assign({}, rest, { styles: selectStyles, className: dropdownClassNames, classNamePrefix: 'jobDone-select', placeholder: locale.placeholder, "data-testid": testId, onFocus: handleFocus, onBlur: handeBlur, isSearchable: isSearchable, isMulti: isMulti, components: {
                    ValueContainer: ({ children, ...props }) => {
                        const options = props.getValue();
                        return (_jsx(components.ValueContainer, Object.assign({}, props, { children: _jsxs("div", Object.assign({ className: styles.optionContainer }, { children: [withAvatar && (_jsx(Avatar, { "aria-label": 'dropdown-option-avatar', size: 's', className: styles.avatar, placeHolder: options[0]?.label, src: options[0]?.src }, void 0)), options[0]?.icon && _jsx("div", Object.assign({ className: styles.icon }, { children: options[0]?.icon }), void 0), children] }), void 0) }), void 0));
                    },
                    DropdownIndicator: (props) => (_jsx(components.DropdownIndicator, Object.assign({}, props, { children: _jsx(Icons.DropdownIcon, { className: styles.dropdownIndicator }, void 0) }), void 0)),
                    Option: ({ children, ...props }) => {
                        const option = props.data;
                        return (_jsx(components.Option, Object.assign({}, props, { children: _jsxs("div", Object.assign({ className: styles.optionContainer }, { children: [withAvatar && (_jsx(Avatar, { "aria-label": 'dropdown-option-avatar', size: 's', className: styles.avatar, placeHolder: option.label, src: option.src }, void 0)), option.icon && _jsx("div", Object.assign({ className: styles.icon }, { children: option.icon }), void 0), children, props.isSelected && !hideCheckIcon && _jsx(Icons.GreenCheck, { className: styles.selectedOptionCheck }, void 0)] }), void 0) }), void 0));
                    },
                } }), void 0) }), void 0) }), void 0));
};
export default Dropdown;
