import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import useStyles from './styles/HorizontalStepper.styles';
import { Body } from '../Typography';
export const HorizontalStepper = ({ initialValue = 0, minLimit = 0, maxLimit = 5, value = 0, onChange = () => { }, disabled = false, enterManually = false, className = '', ...props }) => {
    const classes = useStyles();
    const onChangeHandler = (e) => {
        const newVal = +e.target.value;
        if (isNaN(newVal) || newVal > maxLimit || newVal < minLimit || !Number.isInteger(newVal)) {
            return;
        }
        onChange(newVal);
    };
    const increment = (event) => {
        event.preventDefault();
        onChange(value + 1);
    };
    const decrement = (event) => {
        event.preventDefault();
        onChange(value - 1);
    };
    const incrementDisabled = disabled || value >= maxLimit;
    const decrementDisabled = disabled || value <= minLimit;
    return (_jsxs("div", Object.assign({}, props, { className: cx(classes.container, className) }, { children: [_jsx("button", Object.assign({ type: 'button', "aria-label": 'decrement-button', disabled: decrementDisabled, className: classes.button, onClick: decrement }, { children: "-" }), void 0), enterManually ? (_jsx("input", { "aria-label": 'stepper-input', value: value, onChange: onChangeHandler, className: classes.input, disabled: disabled }, void 0)) : (_jsx(Body, Object.assign({ "aria-label": 'stepper-count-text', className: classes.countText, variant: 'medium' }, { children: value }), void 0)), _jsx("button", Object.assign({ type: 'button', "aria-label": 'increment-button', disabled: incrementDisabled, className: classes.button, onClick: increment }, { children: "+" }), void 0)] }), void 0));
};
