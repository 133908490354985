import { makeStyles } from '@material-ui/core';
import { LabelPlacements } from '../utils';
const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        padding: 0,
        color: theme.palette.grey[400],
        marginRight: props.labelPlacement === LabelPlacements.end ? '6px' : 0,
        marginLeft: props.labelPlacement === LabelPlacements.start ? '6px' : 0,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    }),
    label: {
        userSelect: 'none',
        fontSize: '13px',
        fontWeight: 700,
        lineHeight: '16px',
        color: theme.palette.common.black,
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: `inset 0 0 0 2px ${theme.palette.grey[400]}, inset 0 -2px 0 ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.common.white,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        transition: 'all .3s ease',
        'input:hover ~ &': {
            boxShadow: `inset 0 0 0 2px ${theme.palette.primary.dark}, inset 0 -2px 0 ${theme.palette.primary.dark}`,
        },
        'input:disabled ~ &': {
            boxShadow: `inset 0 0 0 2px ${theme.palette.grey[300]}, inset 0 -2px 0 ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100],
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        transition: 'all .3s ease',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 24%,transparent 25%)',
            content: '""',
        },
        'input:hover ~ &': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));
export default useStyles;
