import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { InputLabel, Box } from '@material-ui/core';
import useStyles from './styles/FormControl.styles';
export const FormControl = ({ id, label, error, active, success, required, children, className, marginBottom, errorMessage, hiddenLabel, }) => {
    const styles = useStyles();
    const labelClassNames = cx(styles.label, {
        [styles.labelSuccess]: success,
        [styles.labelActive]: active,
        [styles.labelError]: error,
    }, className);
    return (_jsxs(Box, Object.assign({ sx: { mb: marginBottom } }, { children: [!hiddenLabel && (_jsxs(InputLabel, Object.assign({ id: id, htmlFor: id, className: labelClassNames }, { children: [label, required && _jsx("span", Object.assign({ className: styles.dot }, { children: "\u2022" }), void 0)] }), void 0)), children, error && !!errorMessage && (_jsx("span", Object.assign({ className: styles.errorMessage }, { children: errorMessage }), void 0))] }), void 0));
};
