import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useRef, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { AutoComplete } from './AutoComplete';
import { Skeleton } from '../../Skeleton';
import { center, libraries } from './common';
const useStyles = makeStyles(() => ({
    mapContainerClassName: {
        width: '100%',
        height: '500px',
        borderRadius: 8,
    },
}));
const AddressAutoComplete = ({ getAddressDetails, locale, hiddenLabel, defaultValue, centerCoordinates, hasError, disabled, debounce, mapContainerClassName, }) => {
    const classes = useStyles();
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAM3YeqvmIWQBkJMLzlsyRuMt7d58fA7xc',
        libraries,
    });
    const [selectedPlace, setSelectedPlace] = useState(null);
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);
    const setAddressDetails = (addressDetails) => {
        setSelectedPlace(addressDetails);
        mapRef?.current?.panTo({ lat: addressDetails.latitude, lng: addressDetails.longitude });
        mapRef?.current?.setZoom(14);
        getAddressDetails?.(addressDetails);
    };
    if (!isLoaded)
        return _jsx(Skeleton, { variant: 'rect', className: cx(classes.mapContainerClassName, mapContainerClassName) }, void 0);
    return !loadError ? (_jsxs("div", { children: [_jsx(AutoComplete, { getAddressDetails: setAddressDetails, center: centerCoordinates || center, locale: locale, hiddenLabel: hiddenLabel, defaultValue: defaultValue, hasError: hasError, disabled: disabled, debounce: debounce }, void 0), _jsx(GoogleMap, Object.assign({ id: 'map', mapContainerClassName: cx(classes.mapContainerClassName, mapContainerClassName), zoom: 8, center: centerCoordinates || center, onLoad: onMapLoad }, { children: selectedPlace && (_jsx(Marker, { position: { lat: selectedPlace.latitude, lng: selectedPlace.longitude } }, `${String(selectedPlace.latitude)}-${String(selectedPlace.longitude)}`)) }), void 0)] }, void 0)) : _jsx(_Fragment, {}, void 0);
};
export default AddressAutoComplete;
