import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Dialog } from './Base';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialogActions-root button': {
            width: 176,
        },
    },
    paperRoot: {
        borderRadius: 0,
    },
    text: {
        color: theme.palette.grey[700],
    },
}));
export const FullScreenDialog = ({ children, ...props }) => {
    const { locale } = props;
    const classes = useStyles();
    return (_jsx(Dialog, Object.assign({}, props, { fullScreen: true, className: classes.root, paperClassName: cx(classes.paperRoot, {
            [String(props.paperClassName)]: !!props.paperClassName,
        }), headerType: 'navigationBar', locale: locale }, { children: children }), void 0));
};
