import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { KeyboardTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons } from '../..';
import { Input } from '../Input';
import { FormControl } from '../FormControl/FormControl';
const useStyles = makeStyles((theme) => ({
    active: {
        color: theme.palette.primary.main,
    },
    error: {
        color: theme.palette.error.main,
    },
}));
export const TimePicker = ({ inputLocale, onChange, value, required, id, hiddenLabel, hasError = false, disabled = false, minutesStep = 5, ariaLabel, className, format, showSeconds, }) => {
    const classes = useStyles();
    const [isActive, setIsActive] = useState(false);
    const renderInput = useCallback((props) => (_jsx(Input, { inputRef: props.inputRef, inputProps: props.inputProps, value: props.value, onClick: (e) => {
            props?.onClick?.(e);
            setIsActive(true);
        }, onChange: props.onChange, disabled: disabled, onBlur: () => {
            setIsActive(false);
        }, required: required, hiddenLabel: true, id: id, locale: {
            placeholder: inputLocale?.placeholder,
        }, error: props.error || hasError || (required && props.value === null), endAdornment: props.InputProps?.endAdornment }, void 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [disabled]);
    return (_jsx("div", Object.assign({ className: className }, { children: _jsx(FormControl, Object.assign({ active: isActive, error: hasError, errorMessage: inputLocale?.errorMessage, label: inputLocale?.label, hiddenLabel: hiddenLabel }, { children: _jsx(MuiPickersUtilsProvider, Object.assign({ utils: DateFnsUtils }, { children: _jsx(KeyboardTimePicker, { clearable: true, "aria-label": ariaLabel, variant: 'inline', margin: 'dense', id: 'time-picker', label: 'From', inputVariant: 'filled', autoOk: true, views: showSeconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes'], disabled: disabled, InputProps: { readOnly: disabled }, minutesStep: minutesStep, format: showSeconds ? 'HH:mm:ss' : format, KeyboardButtonProps: {
                        onFocus: () => setIsActive(true),
                        onBlur: () => setIsActive(false),
                        'aria-label': 'change time',
                    }, keyboardIcon: (_jsx(Icons.Time, { className: cx({
                            [classes.active]: isActive,
                            [classes.error]: hasError,
                        }) }, void 0)), value: value, TextFieldComponent: renderInput, onChange: (e) => {
                        if (e && !isNaN(e.getTime()) && !showSeconds) {
                            onChange(new Date(e.setSeconds(0)));
                        }
                        else {
                            onChange(e);
                        }
                    }, ampm: false }, void 0) }), void 0) }), void 0) }), void 0));
};
