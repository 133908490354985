import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import MUIButton from '@material-ui/core/Button';
import { Loading } from './Loading';
import useStyles from './styles/Button.styles';
import { Variants } from './utils';
export function Button({ variant, color, size, disabled, loading, className, onClick, label, icon, testId, hasFlash, ...props }) {
    const selectedVariant = variant || 'fixedLabel';
    const selectedColor = color || 'primary';
    const selectedSize = size || 'medium';
    const styles = useStyles();
    const classes = cx(styles.root, styles[selectedVariant], styles[selectedColor], styles[`${selectedVariant}_${String(selectedSize)}`], {
        [`${String(className)}`]: !!className,
        [styles.flash]: hasFlash,
    });
    const renderFixedIcon = () => {
        if (icon) {
            if (loading)
                return _jsx(Loading, { color: selectedColor, hasLabel: false }, void 0);
            return _jsx(_Fragment, { children: icon }, void 0);
        }
        return _jsx(_Fragment, { children: ' ' }, void 0);
    };
    const renderFixedLabel = () => {
        if (label) {
            if (loading) {
                return (_jsxs(_Fragment, { children: [_jsx(Loading, { color: selectedColor, hasLabel: true }, void 0), label] }, void 0));
            }
            return _jsx(_Fragment, { children: label }, void 0);
        }
        return _jsx(_Fragment, { children: ' ' }, void 0);
    };
    const renderAutoWidth = () => (_jsxs(_Fragment, { children: [icon, _jsx("span", Object.assign({ className: (icon && label) ? styles.marginLeft : '' }, { children: label }), void 0)] }, void 0));
    const renderContent = () => {
        switch (selectedVariant) {
            case Variants.autoWidth:
                return renderAutoWidth();
            case Variants.fixedIcon:
                return renderFixedIcon();
            case Variants.fixedLabel:
                return renderFixedLabel();
            default:
                return renderFixedLabel();
        }
    };
    const isDisabled = () => {
        if (selectedVariant === Variants.fixedIcon || selectedVariant === Variants.fixedLabel)
            return disabled || loading;
        return disabled;
    };
    return (_jsx(MUIButton, Object.assign({ "data-testid": testId, disableRipple: true, disabled: isDisabled(), onClick: onClick, classes: {
            root: classes,
        } }, props, { children: renderContent() }), void 0));
}
