import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Body } from '../Typography';
import { Dialog } from './Base';
const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.grey[700],
    },
}));
export const ConfirmDialog = ({ ...props }) => {
    const { locale, maxWidth } = props;
    const classes = useStyles();
    return (_jsx(Dialog, Object.assign({}, props, { maxWidth: maxWidth || 'xs', locale: {
            ...locale,
            content: (_jsx(Body, Object.assign({ variant: 'large', className: classes.text }, { children: locale.content }), void 0)),
        } }), void 0));
};
