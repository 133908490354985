import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import cx from 'classnames';
import { Radio as MuiRadio, FormControlLabel } from '@material-ui/core';
import useStyles from './styles/Radio.styles';
import { LabelPlacements } from './utils';
export const Radio = forwardRef(({ className, label, labelPlacement = LabelPlacements.end, labelClassName, radioClassname, parentContainerClassname, ...props }, ref) => {
    const classes = useStyles({ labelPlacement });
    return (_jsx("div", Object.assign({ className: parentContainerClassname }, { children: _jsx(FormControlLabel, { classes: {
                label: cx(classes.label, labelClassName),
            }, label: label, labelPlacement: labelPlacement, control: (_jsx(MuiRadio, Object.assign({ inputRef: ref, disableRipple: true, className: cx(classes.root, radioClassname), color: 'default', checkedIcon: _jsx("span", { className: cx(classes.icon, classes.checkedIcon) }, void 0), icon: _jsx("span", { className: classes.icon }, void 0) }, props), void 0)) }, void 0) }), void 0));
});
