import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    dropdown: {
        marginTop: 8,
        '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    pickerContainer: {
        display: 'inline-flex',
    },
    calendarRoot: {
        display: 'flex !important',
        width: 116,
        border: 'none !important',
        background: theme.palette.common.white,
        borderRadius: 0,
        userSelect: 'none',
        boxSizing: 'content-box',
        '& > .react-datepicker__time-container': {
            width: '100%',
            '& .react-datepicker__time-box': {
                width: '116px !important',
                margin: '0 !important',
                textAlign: 'left !important',
            },
            '& .react-datepicker__time-list': {
                width: '116px !important',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            },
            '& .react-datepicker__time-list::-webkit-scrollbar': {
                width: 0,
                height: 0,
            },
            '& .react-datepicker__time-list-item': {
                padding: '5px 16px !important',
                lineHeight: '30px',
                fontFamily: theme.typography.fontFamily,
                fontStyle: 'normal',
                fontSize: 15,
                fontWeight: '500 !important',
                color: theme.palette.common.black,
            },
            '& .react-datepicker__time-list-item--selected': {
                backgroundColor: `${theme.palette.primary.main} !important`,
            },
            '& > .react-datepicker__header': {
                textAlign: 'left !important',
                padding: '0px !important',
                backgroundColor: theme.palette.common.white,
                borderBottom: 'none',
            },
            '& .react-datepicker-time__header': {
                fontFamily: theme.typography.fontFamily,
                fontSize: '13px',
                lineHeight: '16px',
                color: theme.palette.grey[500],
            },
        },
    },
    firstPickerRoot: {
        borderTopRightRadius: '0 !important',
        borderBottomRightRadius: '0 !important',
    },
    secondPickerRoot: {
        borderLeft: `2px dotted ${theme.palette.grey[300]} !important`,
        borderTopLeftRadius: '0 !important',
        borderBottomLeftRadius: '0 !important',
    },
    displayNone: {
        display: 'none',
    },
    calendarInput: {
        outline: 'none',
        width: '100%',
        fontSize: 14,
        fontWeight: 500,
        minHeight: 40,
        borderRadius: 8,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.grey[100],
        border: '2px solid transparent',
        transition: 'all ease-out 300ms',
        paddingLeft: 16,
        paddingRight: 10,
        '&::placeholder': {
            color: theme.palette.grey[500],
            opacity: 1,
            verticalAlign: 'middle',
        },
        '&:hover': {
            border: `2px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
        },
        '&:focus': {
            color: theme.palette.common.black,
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.common.white,
        },
    },
    timePickerContainer: {
        width: '100%',
    },
    timePickerCalendarRoot: {
        background: theme.palette.common.white,
        boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
        borderRadius: '8px',
    },
    labelClassName: {
        color: theme.palette.common.black,
        fontWeight: 700,
        marginBottom: '0.8rem',
        fontSize: 13,
        textAlign: 'left',
    },
    container: {
        '&:focus-within $labelClassName': {
            color: theme.palette.primary.main,
        },
    },
    errorMessageContainer: {
        fontSize: 13,
        fontWeight: 500,
        marginTop: 6,
        color: theme.palette.error.main,
        textAlign: 'left',
    },
    inputError: {
        borderColor: `${theme.palette.error.main} !important`,
    },
    labelError: {
        color: `${theme.palette.error.main} !important`,
    },
}));
