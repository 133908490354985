import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Icons, Button } from '../..';
import { Subtitle } from '../Typography';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
    titleContainer: {
        display: 'flex',
        height: 36,
        gap: 10,
    },
}));
export const Heading = ({ children, className, icon, title, buttonLabel, onClick, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: cx(className, classes.container) }, rest, { children: [_jsxs("div", Object.assign({ className: classes.titleContainer }, { children: [icon, _jsx(Subtitle, { children: title }, void 0)] }), void 0), buttonLabel && (_jsx(Button, { color: 'ghost', icon: _jsx(Icons.Plus, {}, void 0), label: buttonLabel, onClick: onClick, variant: 'autoWidth' }, void 0))] }), void 0));
};
