import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import OTP from 'react-otp-input';
import cx from 'classnames';
import useStyles from './styles/VerificationCode.styles';
const VerificationCode = ({ isSuccess, inputStyle, containerStyle, className, errorMessageStyles, errorStyle, separator, numInputs, isDisabled, hasErrored, value, onChange, locale, ...props }) => {
    const [isError, setIsError] = useState(hasErrored);
    useEffect(() => {
        setIsError(hasErrored);
    }, [hasErrored]);
    const handleChange = (e) => {
        onChange?.(e);
        if (hasErrored)
            setIsError(false);
    };
    const styles = useStyles();
    const selectedSeparator = separator || _jsx("span", { style: { width: '8px' } }, void 0);
    const selectedNumInputs = numInputs || 6;
    const containerStyles = cx(styles.root, containerStyle);
    const inputStyles = cx(styles.input, inputStyle, {
        [styles.successStyles]: isSuccess,
    });
    const errorStyles = cx(styles.errorStyles, errorStyle);
    return (_jsxs("div", Object.assign({ className: cx(styles.container, className) }, { children: [_jsx(OTP, Object.assign({ separator: selectedSeparator, numInputs: selectedNumInputs, containerStyle: containerStyles, inputStyle: inputStyles, errorStyle: errorStyles, isDisabled: isDisabled || isSuccess, value: value, onChange: handleChange, hasErrored: isError }, props), void 0), isError && (_jsx("span", Object.assign({ className: cx(styles.errorMessage, errorMessageStyles) }, { children: locale?.errorMessage }), void 0))] }), void 0));
};
export default VerificationCode;
