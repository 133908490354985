import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { Avatar } from '../../Avatar';
import { Body } from '../../Typography';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        boxSizing: 'border-box',
        width: 29,
        height: 29,
        border: `2px solid ${theme.palette.common.white}`,
    },
    more: {
        transform: 'translateX(-22px)',
    },
}));
export const MultipleAvatar = ({ avatarPropList = [
    {
        src: 'https://source.unsplash.com/random',
        size: 's',
    },
    {
        src: 'https://source.unsplash.com/random',
        size: 's',
    },
    {
        src: 'https://source.unsplash.com/random',
        size: 's',
    },
    {
        src: 'https://source.unsplash.com/random',
        size: 's',
    },
    {
        src: 'https://source.unsplash.com/random',
        size: 's',
    },
    {
        src: 'https://source.unsplash.com/random',
        size: 's',
    },
], }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: classes.avatarContainer }, { children: [avatarPropList.slice(0, 4).map((avatarProps, i) => (_jsx(Avatar, Object.assign({ className: classes.avatar, style: { transform: `translateX(-${i * 10}px)` } }, avatarProps), `${String(avatarProps.src)}-${i}`))), avatarPropList.length > 4 && (_jsxs(Body, Object.assign({ className: classes.more, display: 'inline-block', variant: 'medium-700' }, { children: ["+", avatarPropList.length - 4] }), void 0))] }), void 0));
};
