import { makeStyles } from '@material-ui/core/styles';
const nextIcon = 'url("data:image/svg+xml,%3Csvg width=\'7\' height=\'10\' viewBox=\'0 0 7 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M6.1142 4.24033C6.57981 4.63942 6.57981 5.35975 6.1142 5.75884L1.65079 9.58462C1.00212 10.1406 1.01432e-07 9.67972 9.12441e-08 8.82537L0 1.17381C-1.01881e-08 0.319454 1.00212 -0.141455 1.65079 0.41455L6.1142 4.24033Z\' fill=\'%237F8897\'/%3E%3C/svg%3E%0A")';
const previousIcon = 'url("data:image/svg+xml,%3Csvg width=\'7\' height=\'10\' viewBox=\'0 0 7 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0.886318 4.24033C0.420707 4.63942 0.420706 5.35975 0.886318 5.75884L5.34973 9.58462C5.9984 10.1406 7.00052 9.67972 7.00052 8.82537V1.17381C7.00052 0.319454 5.9984 -0.141455 5.34973 0.41455L0.886318 4.24033Z\' fill=\'%237F8897\'/%3E%3C/svg%3E%0A")';
export const useStyles = makeStyles((theme) => ({
    oneWeek: {
        '& .react-datepicker__week': {
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            '&:hover .react-datepicker__day:first-child': {
                backgroundColor: theme.palette.primary.main,
                color: `${theme.palette.common.white} !important`,
            },
            '&:hover .react-datepicker__day:last-child': {
                backgroundColor: theme.palette.primary.main,
                color: `${theme.palette.common.white} !important`,
            },
        },
        '& .react-datepicker__day--in-range': {
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            backgroundColor: 'unset',
        },
    },
    twoWeek: {
        '& .react-datepicker__week': {
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            '&:hover .react-datepicker__day:first-child': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${theme.palette.common.white} !important`,
            },
            '&:hover + .react-datepicker__week > .react-datepicker__day:last-child': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: `${theme.palette.common.white} !important`,
            },
            '&:hover + .react-datepicker__week': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        '& .react-datepicker__day--in-range': {
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            backgroundColor: 'unset',
        },
    },
    custom: {
        '& .react-datepicker__day--in-range': {
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
            backgroundColor: `${theme.palette.grey[100]} !important`,
        },
        '& .react-datepicker__day--in-selecting-range': {
            backgroundColor: `${theme.palette.secondary.dark} !important`,
        },
    },
    calendarRoot: {
        display: 'flex !important',
        height: '100%',
        border: 'none !important',
        background: theme.palette.common.white,
        boxShadow: ({ inline }) => (inline ? 'unset' : '0px 16px 32px rgba(22, 36, 61, 0.08)'),
        borderRadius: 8,
        userSelect: 'none',
        '& > .react-datepicker__triangle::after, .react-datepicker__triangle::before': {
            display: 'none',
        },
        '& > .react-datepicker__navigation--previous': {
            width: 10,
            top: 22,
            left: 35,
            content: previousIcon,
        },
        '& > .react-datepicker__navigation--next': {
            width: 10,
            top: 22,
            left: 215,
            content: nextIcon,
        },
        '& > .react-datepicker__month-container > .react-datepicker__header': {
            backgroundColor: 'unset',
            border: 'none',
            margin: '20px 0',
            fontWeight: '700',
            fontSize: 15,
            fontFamily: theme.typography.fontFamily,
        },
        '& > .react-datepicker__month-container': {
            marginLeft: 16,
            marginRight: 16,
            height: '100%',
            '& > .react-datepicker__header > .react-datepicker__day-names': {
                marginTop: 15,
                '& > div': {
                    color: theme.palette.grey[500],
                    fontFamily: theme.typography.fontFamily,
                },
            },
            '& > .react-datepicker__monthPicker': {
                margin: 0,
                height: 'calc(100% - 77px)',
                display: 'flex',
                flexDirection: 'column',
                '& > .react-datepicker__month': {
                    '&--in-range': {
                        color: theme.palette.common.white,
                        backgroundColor: `${theme.palette.primary.main} !important`,
                    },
                    '&-wrapper': {
                        fontFamily: theme.typography.fontFamily,
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 20,
                        '& > .react-datepicker__month': {
                            '&--disabled': {
                                color: theme.palette.grey[500],
                            },
                            '&--selected': {
                                color: `${theme.palette.common.white} !important`,
                                backgroundColor: `${theme.palette.primary.main} !important`,
                            },
                            '&-text': {
                                transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeOut}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 500,
                                borderRadius: 8,
                                width: 60,
                                height: 36,
                                fontSize: 14,
                                '&:hover': {
                                    backgroundColor: theme.palette.secondary.dark,
                                },
                                '&--keyboard-selected': {
                                    backgroundColor: 'unset',
                                    color: 'unset',
                                },
                            },
                        },
                    },
                },
            },
            '& > .react-datepicker__month': {
                marginBottom: 20,
                '& > .react-datepicker__week': {
                    borderRadius: 8,
                    '& > .react-datepicker__day': {
                        fontFamily: theme.typography.fontFamily,
                        width: 'calc(100% / 7)',
                        borderRadius: 8,
                        padding: 2,
                        margin: 0,
                        transition: theme.transitions.create('all', { duration: theme.transitions.duration.short }),
                        fontWeight: 500,
                        fontSize: 14,
                        color: theme.palette.common.black,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.dark,
                        },
                        '&--outside-month': {
                            color: theme.palette.grey[400],
                        },
                        '&--keyboard-selected': {
                            backgroundColor: 'unset',
                        },
                        '&--disabled': {
                            color: theme.palette.grey[500],
                        },
                        '&--selected': {
                            backgroundColor: 'unset',
                            position: 'relative',
                        },
                        '&--range-start, &--range-end': {
                            position: 'relative',
                            borderRadius: '8px !important',
                            backgroundColor: `${theme.palette.primary.main} !important`,
                            color: theme.palette.common.white,
                        },
                        '&--in-range': {
                            backgroundColor: 'unset',
                        },
                        '&--today': {
                            backgroundColor: 'unset',
                            position: 'relative',
                            '&::after': {
                                position: 'absolute',
                                content: '""',
                                bottom: -2,
                                left: 'calc(50% - 3px)',
                                backgroundColor: theme.palette.primary.main,
                                width: 6,
                                height: 6,
                                borderRadius: '100%',
                            },
                        },
                    },
                },
            },
        },
        '& > .react-datepicker__year--container > .react-datepicker__header': {
            backgroundColor: 'unset',
            border: 'none',
            margin: '20px 0',
            fontWeight: '700',
            fontSize: 15,
            fontFamily: theme.typography.fontFamily,
        },
        '& > .react-datepicker__year--container': {
            marginLeft: 16,
            marginRight: 16,
            marginBottom: 16,
            height: '100%',
            '& > .react-datepicker__year': {
                height: 'calc(100% - 77px)',
                margin: 0,
                '& > .react-datepicker__year-wrapper': {
                    fontFamily: theme.typography.fontFamily,
                    display: 'flex',
                    gap: 8,
                    justifyContent: 'space-between',
                    maxWidth: 280,
                    '& > .react-datepicker__year': {
                        '&-text--disabled': {
                            color: theme.palette.grey[500],
                        },
                        '&-text--selected': {
                            color: `${theme.palette.common.white} !important`,
                            backgroundColor: `${theme.palette.primary.main} !important`,
                        },
                        '&-text': {
                            transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeOut}`,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 500,
                            borderRadius: 8,
                            width: 60,
                            height: 36,
                            fontSize: 14,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.dark,
                            },
                            '&--keyboard-selected': {
                                backgroundColor: 'unset',
                                color: 'unset',
                            },
                        },
                    },
                },
            },
        },
    },
    rangeTypes: {
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 16px 15px 8px',
        padding: '8px 0',
        gap: 12,
    },
    selectedWeek: {
        backgroundColor: theme.palette.grey[100],
    },
    buttonContainer: {
        borderRadius: 8,
        margin: 0,
        padding: 0,
        border: 'none',
        cursor: 'inherit',
    },
}));
export const useInputStyles = makeStyles((theme) => ({
    calendarInput: {
        '& > div > div > input': {
            fontWeight: 500,
        },
    },
    calendarEndAdornment: {
        marginRight: 18,
        '&>svg': {
            color: theme.palette.grey[500],
        },
    },
}));
