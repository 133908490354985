import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import { Chip as ChipMUI } from '@material-ui/core';
import cx from 'classnames';
import { Icons } from '../..';
import { theme } from '../../utils';
const useStyles = makeStyles(() => ({
    root: () => ({
        padding: '4px 8px',
        borderRadius: 8,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '24px',
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.common.black,
        height: 32,
        transition: '0.3s',
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
        },
        '& .MuiChip-label': {
            paddingLeft: 0,
            paddingRight: 4,
        },
    }),
    closeIcon: {
        margin: 0,
        '& > svg': {
            color: theme.palette.grey[500],
        },
    },
}));
export const Chip = ({ label = 'Option', className, onDelete, ...props }) => {
    const classes = useStyles();
    return (_jsx(ChipMUI, Object.assign({}, props, { className: cx(classes.root, className), label: label, deleteIcon: _jsx(Icons.Close, { className: classes.closeIcon }, void 0), onDelete: onDelete }), void 0));
};
